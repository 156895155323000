import React, {useState} from "react";
import {Col, FormControl, FormText, InputGroup, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import AddRobotModal from "./AddRobotModal";

export default function AddRobot({record, settings}) {

    const [isOpen, setIsOpen] = useState(0);

    function handleAddRobot() {
        setIsOpen(1);
        console.log("SETTINGS", settings);
        console.log("RECORD", record);
    }

    return (
        <Row className="mb-3">
            <AddRobotModal isOpen={isOpen} setIsOpen={setIsOpen} record={record} settigns={settings}/>
            {/*{settings_elements}*/}
            <Col md={1} className="mt-2">
                <InputGroup className="input-group-sm">
                    <Button variant="outline-primary" className="btn-sm" onClick={handleAddRobot}>Add robot</Button>
                </InputGroup>
            </Col>
        </Row>
    );
}