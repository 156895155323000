import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import {InputGroup, Pagination, PageItem, Row, Col, FormSelect, FormText, FormControl} from "react-bootstrap";

export default function TypeSelect({handleChange, defaultValue}){
    const handleTypeChange = (event) => {
        handleChange(parseInt(event.target.value));
    }
    return (
        <FormSelect onChange={handleTypeChange} value={defaultValue}>
            <option value="0">Long</option>
            <option value="1">Short</option>
        </FormSelect>
    );

}