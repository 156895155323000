import {Table} from "react-bootstrap";
import {useState} from "react";
import IndicatorsTable from "./IndicatorsTable";

export default function Indicators({refreshApp}) {

    return (
        <>
            <div className={"mt-3"}>
                <h5>Indicators</h5>

            </div>
            <IndicatorsTable refreshApp={refreshApp}/>
        </>
    )

}