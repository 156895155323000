import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import {InputGroup, Pagination, PageItem, Row, Col, FormSelect, FormText, FormControl} from "react-bootstrap";

export default function CountSelect({pagination, handleCountChange}){


    const counts = [
        {'count': 10},
        {'count': 20},
        {'count': 30},
        {'count': 50},
        {'count': 100},
        {'count': 500},
        {'count': 1000}
    ]

    const handleChange = (event) => {
        handleCountChange(parseInt(event.target.value));
    }
    return (
            <InputGroup className="input-group-sm">
                <FormSelect onChange={handleChange} value={pagination.count}>
                    {counts.map(item => (
                        <option value={item.count}>{item.count}</option>
                    ))}
                </FormSelect>
            </InputGroup>
    );

}