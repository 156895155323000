import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import NavDropdown from 'react-bootstrap/NavDropdown';
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
// import {Intersect, Motherboard, RocketTakeoff} from "react-bootstrap-icons";

// Custom app
import LogoutButton from "../../auth/LogoutButton";

function TopNavbar({refreshApp, menu, setMenu}) {

    // Get current location from path
    const location = useLocation();

    useEffect(() => {
        // Check if menu is empty and set current router path as menu
        if (!menu) {
            // Set current path as menu
            setMenu(location.pathname);
        } else if (menu === '/' || menu === '') {
            // Default path. Set menu and navigate to default route.
            setMenu("/backtests");
            history("/backtests");
        }
    }, []);

    // Get navigate to change path
    const history = useNavigate();

    // Set menu and change current location (for browser only, not for backend)
    const changeLocation = (location) => {
        setMenu(location);
        history(location);
    }

    return (
        <>
            <Navbar expand="sm" className="bg-body-tertiary">

                    <Navbar.Brand href="#home">BRAND</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">


                        <Nav className="me-auto">
                            {/*<Nav.Link active={menu==='/main'} onClick={() => changeLocation("/main")} href="#">Main</Nav.Link>*/}
                            <Nav.Link active={menu==='/robots'} onClick={() => changeLocation("/robots")} href="#">Robots</Nav.Link>
                            <Nav.Link active={menu==='/grouptests'} onClick={() => changeLocation("/grouptests")} href="#">Grouptests</Nav.Link>
                            <Nav.Link active={menu==='/backtests'} onClick={() => changeLocation("/backtests")} href="#">Backtests</Nav.Link>
                            <Nav.Link active={menu==='/indicators'} onClick={() => changeLocation("/indicators")} href="#">Indicators</Nav.Link>
                            <Nav.Link active={menu==='/engines'} onClick={() => changeLocation("/engines")} href="#">Engines</Nav.Link>
                        </Nav>
                        <Nav>
                        <LogoutButton refreshApp={refreshApp} buttonText={"Logout"}/>
                        </Nav>

                        {/*{(() => {*/}
                        {/*    if (menu==='/backtests') {*/}
                        {/*        return (*/}
                        {/*            <Nav className="me-auto">*/}
                        {/*                <div className="float-start"> <h5 className="mt-2"><RocketTakeoff/> Backtests</h5></div>*/}
                        {/*            </Nav>*/}
                        {/*        )*/}
                        {/*    } else if (menu==='/indicators') {*/}
                        {/*        return (*/}
                        {/*            <Nav className="me-auto">*/}
                        {/*                <div className="float-start"> <h5 className="mt-2"><Intersect/> Backtests</h5></div>*/}
                        {/*            </Nav>*/}
                        {/*        )*/}
                        {/*    } else if (menu==='/engines') {*/}
                        {/*        return (*/}
                        {/*            <Nav className="me-auto">*/}
                        {/*                <div className="float-start"> <h5 className="mt-2"><Motherboard/> Robots</h5></div>*/}
                        {/*            </Nav>*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*})()}*/}

                    </Navbar.Collapse>


            </Navbar>
        </>
    );
}

export default TopNavbar;