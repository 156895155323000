import React, {useEffect, useState} from "react";
import Transport from "../../../../transport/Transport";
import {Col, FormControl, Accordion, FormText, InputGroup, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import PresetsControl from "../../../shared/components/PresetsControl";
import EngineSelect from "../../../shared/components/EngineSelect";


export default function EnginesSettingsControl({refreshApp, changeEngine}){

    //const [tickers, setTickers] = useState([])
    const [engine, setEngine] = useState({'id': 0, 'params': {}});
    const [engines, setEngines] = useState([]);
    const [total, setTotal] = useState(0);

    function calcTotal  (params)  {
        let counter = 0;
        for (var key in params) {
            console.log("Calc key", key);
            let temp = params[key].split(",");
            if (counter === 0) {
                counter = temp.length;
            } else {
                counter = counter * temp.length;
            }
        }
        return counter;
    }


    function handleEngineChange (id) {
        //let id= parseInt(event.target.value);
        if (id ===0) {
            setEngine({'params': {}});
            setTotal(0);
        } else {
            for (let i=0; i<engines.length; i++) {
                if (id === engines[i].id) {
                    let new_engine = engines[i];
                    if (typeof new_engine.params === 'string') {
                        new_engine.params = JSON.parse(new_engine.params)
                        Object.keys(new_engine.params).map((key, index) => {
                            new_engine.params[key] = new_engine.params[key].default;
                        })
                    }
                    let new_total = calcTotal(new_engine.params);
                    setTotal(new_total);
                    setEngine(new_engine);
                    changeEngine(new_engine);
                    break;
                }
            }
        }
    }

    function filterEdit(value) {
        let new_value = "";
        const allowed = ['-', '0','1','2','3','4','5','6','7','8','9', ',',"."];
        for (let i = 0; i < value.length; i++) {
            if (allowed.includes(value[i])) new_value += value[i];
        }
        return new_value;
    }

    function handleEdit (event)  {
        const name = event.target.attributes.name.value;
        let new_engine = JSON.parse(JSON.stringify(engine))
        const value = event.target.value;
        console.log("SETTING", name, filterEdit(value));
        new_engine.params[name]  =  filterEdit(value);
        let counter= calcTotal(new_engine.params);
        setTotal(counter);
        setEngine(new_engine);
        changeEngine(new_engine);
    }

    function handleListResponse(response) {
        setEngines(response.list);
    }

    useEffect(() => {
        Transport("engines", "full_list", {}, handleListResponse, refreshApp);
    }, []);

    function setEnginePreset(new_engine) {
        let new_total = calcTotal(new_engine.params);
        setTotal(new_total);
        setEngine(new_engine);
        changeEngine(new_engine);
    }

    return (
        <>
            <Row>
                <Col md={4} className={"mt-1"}>
                    <FormText>[engine]</FormText>
                    <InputGroup size={"sm"} className="mt-1">
                        <EngineSelect engines={engines} setEngine={handleEngineChange} engine={engine.id}/>
                    </InputGroup>
                </Col>
                <Col md={3} className={"mt-1"}>

                </Col>
                <Col md={5} className={"mt-1"}>
                    <FormText>[presets]</FormText>
                    <PresetsControl refreshApp={refreshApp} type={3} param={0} value={engine} setValue={setEnginePreset}/>
                </Col>

            </Row>
            <Row>
                {Object.keys(engine.params).map((key, index) => (
                    <Col md={4}>
                        <div className={"mt-2"}>
                            <FormText className={"mt-1"}>[{key}]</FormText>
                            <InputGroup size={"sm"} className={"mt-1"}>
                                <FormControl onChange={handleEdit}  type="text" maxLength={100} className="form-control-sm" name={key} value={engine.params[key]}/>
                            </InputGroup>
                        </div>
                    </Col>
                ))}
                <Col md={4}>
                    <div className={"mt-2"}>
                    <FormText>[total strategies]</FormText>
                    <InputGroup size={"sm"} className={"mt-1"}>
                        { total <= 500000 ? (<div className="text-success">{total}</div>): (total <= 1000000 ? (<div className="text-warning">{total}</div>): (<div className="text-danger">{total}</div>))}
                    </InputGroup>
                    </div>
                </Col>
            </Row>

        </>
    );

}