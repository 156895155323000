import React, {useEffect, useState} from "react";
import PositionModal from "../../backtests/modals/PositionModal";
import { useNavigate } from "react-router-dom";
import {renderCharts} from "../../backtests/components/PositionCharts";

import {Spinner, Table} from "react-bootstrap";
import NewPositionLine from "../../backtests/components/NewPositionLine";
import NewPositionHeaderLine from "../../backtests/components/NewPositionHeaderLine";
import StrategySettingsControl from "../../backtests/components/StrategySettingsControl";
import {renderPriceProfitChart} from "../../backtests/components/PositionCharts";
import StrategyFullStats from "../../backtests/components/StrategyFullStats";
import Transport from "../../../../transport/Transport";
import AddRobot from "../../backtests/components/AddRobot";

export default function GroupStrategyPositions({refreshApp, searchParams, setSettingsHash}) {

    const [loading, setLoading] = useState(true);
    const [record, setRecord] = useState({'price_chart': []});
    const [trades, setTrades] = useState([]);
    const [settings, setSettings] = useState([]);
    const [priceDepositChart, SetPriceDepositChart] = useState([]);
    const [positionModalOpen, setPositionModalOpen] = useState(false);
    const [position, setPosition] = useState(0);
    const navigate = useNavigate();

    const [rendered, setRendered] = useState([]);

    function handleRecordResponse(response) {
        setTrades(response.trades);
        setSettings(response.settings);
        setRecord(response.backtest);
        setSettingsHash(response.settings_hash);
        //Chart series
        let chart_options = JSON.parse(JSON.stringify(priceDepositChart));
        chart_options.push(response.backtest.price_chart);
        SetPriceDepositChart(chart_options);
        renderPriceProfitChart(chart_options, response.trades);
        setLoading(false);
    }

    function update () {
      //  setLoading(true);
        let newsparam = searchParams;
        Transport('grouptests', 'strategy', newsparam, handleRecordResponse, refreshApp);

    }

    // function handleChartResponse(response) {
    //
    // }
    //

    function getChart(positionChartSettings, index) {
        Transport('backtests', 'chart', positionChartSettings, (json) => {
             //   console.log("GOT CHART", json.chart);
                console.log("SETTINGS", settings);
                let take = parseFloat(settings['min_take']);
                if (record.type === 1) take = -take;
                var new_rendered = renderCharts(json.chart, take, record, trades[index], rendered);
               // console.log("RR11RR", json.chart);
                setRendered(new_rendered);
                setPositionModalOpen(true);
                }, refreshApp);
    }

    useEffect(() => {
          update();
    }, []);


    const openPositionModal = (e) => {
        let index = e.target.attributes.val.value;
        setPosition(trades[index]);
       // console.log("OOPEN TIME", trades[index].open_time);
        let margin = trades[index].duration / 3 * 1000;
        if (margin < 60 * 60 * 1000) margin = 60 * 60 * 1000
        let chart_settings = {'bid': searchParams['tid'], 'start': trades[index].open_time - margin, 'stop':trades[index].close_time + margin}
        getChart(chart_settings, index);
    }

    const goUrl = (e) => {
        console.log("Navigating to " , e);
        let url = "/strategy" + e.target.attributes.url.value;
        console.log("URL", url);
        navigate(url);
    }

    if (loading)  return (
        <div className="text-center mt-5">
            <Spinner animation="border" role="status" >
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );



    return (
        <>
        <StrategyFullStats record={record} trades={trades}/>
        <hr/>
        <StrategySettingsControl record={record} settings={settings}/>
        <hr/>
        <AddRobot refreshApp={refreshApp} record={record} settings={settings}/>
        <hr/>
        <PositionModal isOpen={positionModalOpen} setModalOpen={setPositionModalOpen} record={record} position={position} settings={settings}/>
        {/*<PriceDepositChart series={priceDepositChart} trades={trades} />*/}
        <div id="price_profit_chart"></div>
        <hr/>
        <Table>
            <tr>
                <NewPositionHeaderLine/>
            </tr>
                {trades.map((trade, index) => (

                        <NewPositionLine trade={trade} index={index} openPositionModal={openPositionModal}/>

                ))}
        </Table>
        </>);
}