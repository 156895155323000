import './scss/main.scss';
import Login from "./app/auth/Login";
import React, {useState} from "react";
import {Container} from "react-bootstrap";
import {BrowserRouter, Route, Routes} from "react-router-dom";

// Custom app
import TopNavbar from "./app/shared/components/TopNavbar";

// Views
import NotFound from "./app/views/notfound/NotFound";
import Dashboard from "./app/views/dashboard/Dashboard";
import Records from "./app/views/records/Records";
import Indicators from "./app/views/indicators/Indicators";
import Engines from "./app/views/engines/Engines";
import Backtests from "./app/views/backtests/Backtests";
import BacktestTop from "./app/views/backtests/BacktestTop";
import Strategy from "./app/views/backtests/Strategy";
import Robots from "./app/views/robots/Robots";
import Grouptests from "./app/views/grouptests/Grouptests";
import Grouptest from "./app/views/grouptests/Grouptest";
import GroupStrategy from "./app/views/grouptests/GroupStrategy";

function App() {

    // Current selected menu item
    const [menu, setMenu] = useState();

    // Refresh main app using state
    const [refresh, setRefresh] = useState(0);
    const refreshApp = () => setRefresh(refresh + 1);

    // Get token and check if auth needed
    const token = localStorage.getItem('flapary_token');
    if (!token) return <Login refreshApp={refreshApp}/>

    // Process normally authorized render
    return (
        <>
            <BrowserRouter>
            <Container className={"mt-2 pb-5"} >
                <TopNavbar refreshApp={refreshApp} menu={menu} setMenu={setMenu}/>
                <Routes>
                    {/*<Route path="/main" element={<Dashboard refreshApp={refreshApp}/>}/>*/}
                    <Route path="/" element={<Backtests refreshApp={refreshApp}/>}/>
                    <Route path="/backtests" element={<Backtests refreshApp={refreshApp}/>}/>
                    <Route path="/grouptests" element={<Grouptests refreshApp={refreshApp}/>}/>
                    <Route path="/grouptest" element={<Grouptest refreshApp={refreshApp}/>}/>
                    <Route path="/indicators" element={<Indicators refreshApp={refreshApp}/>}/>
                    <Route path="/engines" element={<Engines refreshApp={refreshApp}/>}/>
                    <Route path="/top" element={<BacktestTop refreshApp={refreshApp}/>}/>
                    <Route path="/strategy" element={<Strategy refreshApp={refreshApp}/>}/>
                    <Route path="/group_strategy" element={<GroupStrategy refreshApp={refreshApp}/>}/>
                    <Route path="/robots" element={<Robots refreshApp={refreshApp}/>}/>
                    <Route path='*' exact={true} element={<NotFound/>} />
                </Routes>
            </Container>
            </BrowserRouter>
        </>
    );
}

export default App;
