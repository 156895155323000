import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {InputGroup, Pagination, Form, PageItem, Row, Col, FormSelect, FormText, FormControl} from "react-bootstrap";







export default function DateTimePicker({timestamp, setTimestamp}) {


    const [actualDate, setActualDate] = useState();
    const [actualTime, setActualTime] = useState();

    function getFormattedDate (timestamp) {
        let date = new Date(timestamp * 1000);
        let year = date.getFullYear();
        if (isNaN(year)) return actualDate;
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        let formatted_date = year + "-" + month + '-' + day;
        return formatted_date;
    }

    function getFormattedTime (timestamp) {
        let date = new Date(timestamp * 1000);
        let hours = date.getHours();

        if (isNaN(hours)) return actualTime;
        let mins = (date.getMinutes()).toString().padStart(2, '0');
        let seconds = date.getSeconds().toString().padStart(2, '0');
        let formatted_time = hours + ":" + mins; // + ':' + seconds;



        return formatted_time;
    }



    function handleEditFunction(key, timestamp) {
        let date = getFormattedDate(timestamp);
        let time = getFormattedTime(timestamp);
        if (time === 'NaN:NaN:NaN') time = "00:00:00";
        //alert("GOT DATE", date, time);
        return (e) => {
            //console.log("ELEMENT", e);
            if (key === 'date') {
                date = e.target.value;
                setActualDate(date);
            } else if (key === 'time') {
                time = e.target.value;
                setActualTime(time);
            }
            let newDate = Date.parse(date + " " + time);

            setTimestamp(parseInt(newDate.valueOf()) / 1000);
        }
    }


    return (
        <>
                <FormControl type="date" size={"sm"} onChange={handleEditFunction('date', timestamp)}  value={getFormattedDate(timestamp)}/>
                <FormControl type="time" size={"sm"} onChange={handleEditFunction('time', timestamp)}  value={getFormattedTime(timestamp)}/>
        </>
    );
}