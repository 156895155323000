

import Modal from "react-bootstrap/Modal";
import React, {useCallback, useMemo, useRef, useState} from "react";
import {Col, Form, FormControl, FormText, Row} from "react-bootstrap";

import Transport from "../../../../transport/Transport";
import Button from "react-bootstrap/Button";
import StrategySettingsControl from "./StrategySettingsControl";


export default function AddRobotModal({refreshApp, isOpen, setIsOpen, record, settigns}) {


    const [balance, setBalance] = useState(100);
    const [name, setName] = useState("New robot");
    function closeModal ()  {
        // Set state to closed
        setIsOpen(false);
    }

    function handleEditName(e) {
        setName(e.target.value);
    }

    function handleEditBalance(e) {
        setBalance(parseInt(e.target.value));
    }

    function handleAddRobotResponse(response) {
        setIsOpen(false);
    }

    function handleSaveFunction() {
        let newRobot = Object.assign({}, settigns);
        newRobot['gid'] = 0;
        let temp = localStorage.getItem("pagination_robots")
        // if (temp !== "undefined") {
        //     let get_pagination = JSON.parse(temp);
        //     if (get_pagination != null && get_pagination !== {})  newRobot['gid'] = get_pagination['group'];
        //
        // }
        newRobot['sid'] = record['id'];
        newRobot['type'] = record['type'];
        newRobot['engine'] = record['engine']['id']
        newRobot['sources'] = record['sources']
        newRobot['balance'] = balance;
        newRobot['name'] = name;
        Transport("robots", "update", newRobot, handleAddRobotResponse, refreshApp);
    }

    return (
        <Modal show={isOpen} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add new robot
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={6} key={"col_name"}>
                            <FormText key={"label_name"}>Name</FormText>
                            <FormControl key={"input_name"} type="text" size="sm" onChange={handleEditName} defaultValue={name} className={"mt-1"}/>
                        </Col>
                        <Col md={6} key={"col_balance"}>
                            <FormText key={"label_balance"}>Initial balance</FormText>
                            <FormControl key={"input_balance"} type="number" size="sm" onChange={handleEditBalance} defaultValue={balance} className={"mt-1"}/>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size={"sm"} variant="outline-primary" onClick={handleSaveFunction}>Add robot</Button>
                <Button size={"sm"} variant="outline-secondary"  onClick={closeModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}