import {Alert, Col, Row} from "react-bootstrap";

export default function NotFound({refreshApp}) {
    return (
        <>
        <Row className="justify-content-center mt-3">
            <Col xs={10} md={4}>
            <Alert variant={"danger"}>
            Not found.
            </Alert>
            </Col>
        </Row>
        </>
    );
}