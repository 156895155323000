import React, {useEffect, useState} from "react";
import Transport from "../../../../transport/Transport";
import {Col, FormControl, Accordion, FormText, InputGroup, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import PresetsControl from "../../../shared/components/PresetsControl";


export default function TickersSelectButtons({refreshApp, selected, setSelected}){

    //const [tickers, setTickers] = useState([])
    const [tickers, setTickers] = useState([]);
    const [isOpen, setIsOpen] = useState("0");

    function handleListResponse(response) {
        setTickers(response.list);
        console.log("Tickers response", response);
    }

    useEffect(() => {
        Transport("tickers", "list", {}, handleListResponse, refreshApp);
    }, []);

    function handleToggle(e){
        console.log("Got event", e);
        let option = e.target.attributes.value.value;

        let new_selected = selected;

        if (new_selected.includes(option)) {
            new_selected = (new_selected.filter((item) => item !== option));
        } else {
            new_selected = [...selected, option];
        }
        setSelected(new_selected);
        e.preventDefault();
    }

    function getSelected() {
        let text_selected = "";
        if (selected !== undefined) {
            for(let i = 0; i < selected.length; i++) {
                if (i > 0) text_selected += ", ";
                text_selected += selected[i].toUpperCase();
            }
        }
        return text_selected;
    }

    function changeOpen() {
        if (isOpen === "0") setIsOpen("1"); else setIsOpen("0");
    }

    function getSelectedCount() {
        if (selected !== undefined) {
            return selected.length;
        }
        return 0;
    }

    function handleSelectAll() {
        setSelected(tickers);
    }

    function handleSelectNone() {
        setSelected([]);
    }
    return (
        <>
            <Row>
            <Col md={7}>
                <div className="mt-1">
                    <FormText>[tickers]</FormText>
                    <InputGroup size={"sm"} className={"mt-1"}>
                        <FormControl size={"sm"} value={getSelected()} onClick={changeOpen}/>
                        <InputGroup.Text>{getSelectedCount()} selected</InputGroup.Text>
                        <Button variant="outline-primary" onClick={changeOpen}> { isOpen === "0" ? (<div>Show</div>) : (<div>Hide</div>)}</Button>
                    </InputGroup>
                </div>
            </Col>
            <Col md={5}>
                    <div className="mt-1">
                        <FormText>[presets]</FormText>
                        {/*<FormText>Load and save tickers preset</FormText>*/}
                        <PresetsControl refreshApp={refreshApp} type={0} param={0} value={selected} setValue={setSelected}/>
                        {/*<PresetsControl presets={presets[1]} pname={pname2} ptype={1} pparam={0} setPname={setPname2} handleDeletePreset={handleDeletePreset} handlePresetChange={handleChangePreset2} handleCreatePreset={handleCreatePreset2}/>*/}
                    </div>
            </Col>
            </Row>
                <Accordion defaultActiveKey="1" className={"mt-2"}>
                    <Accordion.Item active={isOpen} eventKey={isOpen}>
                        <Accordion.Body className={"p-1"}>
                            <Row>
                                <Col md={12} className={"mt-1"}>
                                        {tickers.map(option => (
                                            <>
                                            { selected.includes(option) ? (
                                                    <Button size={"sm"} variant="primary" value={option} onClick={handleToggle} className={"m-1"}>{option.toUpperCase()}</Button>
                                                ) : (
                                                    <Button size={"sm"} variant="outline-primary" value={option} onClick={handleToggle} className={"m-1"}>{option.toUpperCase()}</Button>
                                                )
                                            }
                                            </>
                                        ))}
                                </Col>
                                <Col md={12}>
                                    <Button size={"sm"} variant="outline-primary" onClick={handleSelectAll} className={"m-1"}>Select all</Button>
                                    <Button size={"sm"} variant="outline-primary" onClick={handleSelectNone} className={"m-1"}>Remove selection</Button>
                                </Col>

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

        </>
    );

}