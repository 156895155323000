import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";
import StrategyPositions from "./components/StrategyPositions";

export default function Strategy({auth}) {

    const [settingsHash, setSettingsHash] = useState("");
    function paramsToObject(entries) {
        const result = {}
        for(const [key, value] of entries) { // each 'entry' is a [key, value] tupple
            result[key] = value;
        }
        return result;
    }

    let location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const params_only = paramsToObject(searchParams.entries());


    const get_url = () => {
        const url = "/top?id=" + params_only['bid'];
        return url;
    }
    //console.log("PPPAAA", params_only);

    return (
        <div>
        <Breadcrumb>

            <Breadcrumb.Item ><Link to="/backtests">Backtests</Link></Breadcrumb.Item>
            <Breadcrumb.Item ><Link to={get_url()}>Backtest #{params_only.bid}</Link></Breadcrumb.Item>
            <Breadcrumb.Item active>Strategy {settingsHash}</Breadcrumb.Item>
        </Breadcrumb>
            <StrategyPositions auth={auth} searchParams={params_only} setSettingsHash={setSettingsHash}/>
        </div>
    );
}
