import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {InputGroup, Pagination, Form, PageItem, Row, Col, FormSelect, FormText, FormControl} from "react-bootstrap";
import { DateTime } from 'react-datetime-bootstrap';
import GroupSelect from "../../../shared/components/GroupSelect";
import IndicatorSelect from "../../../shared/components/IndicatorSelect";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import Transport from "../../../../transport/Transport";
import PresetsControl from "../../../shared/components/PresetsControl";

import DateTimeField from "@texada/date-picker";
import "@texada/date-picker/dist/styles.min.css";
import DateTimePicker from "../../../shared/components/DateTimePicker";





export default function TimeFramesControl({refreshApp, timeframes, setTimeframes}) {
    function onValueChange  (index, type, newtimestamp)  {
        let newTimeframes = Object.assign([], timeframes);
        console.log("SETTING NEW", index, type, newtimestamp);
        if (isNaN(newtimestamp)) {
            var date = new Date();
            var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
            newtimestamp = Math.floor(now_utc / 1000);
        }
        newTimeframes[index][type] = newtimestamp;
        setTimeframes(newTimeframes);

    }
    function handleEditFunction (index, type) {
        // Add determinated param to event
        return (newtimestamp) => onValueChange(index, type, newtimestamp);
    }
    function handleEditName (index) {
        // Add determinated param to event
        return (e) => {
            let new_timeframes = Object.assign([], timeframes);
            new_timeframes[index][2] = e.target.value;
            setTimeframes(new_timeframes);
        }
    }
    function addNew(e) {
        let new_timeframes = Object.assign([], timeframes);
        var date = new Date();
        var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        new_timeframes.push([Math.floor(now_utc / 1000) - 60 * 60 * 24 * 90 + 60 * 60, Math.floor(now_utc / 1000), "New timeframe"]);
        setTimeframes(new_timeframes);
    }
    function handleRemoveFunction (e) {
        // Add determinated param to event
        let new_timeframes = Object.assign([], timeframes);
        new_timeframes.splice(parseInt(e.target.value), 1);
        setTimeframes(new_timeframes);
    }
    useEffect(() => {
        addNew();
    }, []);

    return (
        <>
            <Row>
                <Col md={11}>
                    <FormText>[timeframes]</FormText>
                    {timeframes.map((item, index) => (
                        <>
                            <Row>
                                <Col md={7}>
                                    <InputGroup size={"sm"} className={"mt-1"}>
                                        <DateTimePicker timestamp={item[0]} setTimestamp={handleEditFunction(index, 0)}/>
                                        <FormText  className={"ms-2 me-2"}> -> </FormText>
                                        <DateTimePicker timestamp={item[1]} setTimestamp={handleEditFunction(index, 1)}/>
                                    </InputGroup>
                                </Col>
                                <Col md={1}>
                                    <InputGroup size={"sm"} className={"mt-1"}>
                                        <FormText>
                                            {Math.ceil((item[1] - item[0]) / 60 / 60 / 24)} d
                                        </FormText>
                                    </InputGroup>
                                </Col>

                                <Col md={3}>
                                    <InputGroup size={"sm"} className={"mt-1"}>
                                        <FormControl key={"input_name" + index} type="text" size="sm" onChange={handleEditName(index)} Value={item[2]}/>
                                    </InputGroup>
                                </Col>
                                <Col md={1}>
                                    <InputGroup size={"sm"} className={"mt-1"}>
                                    <Button variant="outline-danger" size={"sm"} onClick={handleRemoveFunction} value={index}>Remove</Button>
                                    </InputGroup>
                                </Col>
                            </Row>


                        </>
                    ))}
                </Col>
                <Col md={1}>
                    <FormText>&nbsp;</FormText>
                    <InputGroup className="input-group-sm mt-1">
                        <Button variant="outline-primary" onClick={addNew}>Add</Button>
                    </InputGroup>
                </Col>
                <Col md={5} className={"mt-1"}>
                    <FormText>[presets]</FormText>
                    <PresetsControl refreshApp={refreshApp} type={5} param={0} value={timeframes} setValue={setTimeframes}/>
                </Col>
            </Row>
        </>
    );
}