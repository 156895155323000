import React from "react";
import {Table} from "react-bootstrap";

export default function StrategySettingsDisplay({settings}) {


    let settings_elements = [];

    for(const [key, value] of Object.entries(settings)) {
            settings_elements.push(<>
                <td>{key} = {value}</td>
        </>)
    }

    return (
    <Table>
       <tr>
                {settings_elements}
       </tr>
    </Table>
    );
}