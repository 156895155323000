import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {InputGroup, Pagination, PageItem, Row, Col, FormSelect, FormText, FormControl} from "react-bootstrap";
import GroupSelect from "./GroupSelect";
import PresetSelect from "./PresetSelect";
import Transport from "../../../transport/Transport";
import BacktestModal from "../../views/backtests/modals/BacktestModal";
import PresetModal from "../modals/PresetModal";
import ConfirmationModal from "../modals/ConfirmationModal";

export default function PresetsControl({refreshApp, type, param, value, setValue}) {


    const [preset, setPreset] = useState(0);
    const [presets, setPresets] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [record, setRecord] = useState({});

    function handleListResponse(response) {
        setPresets(response.list);
        console.log("Presets response", response);
    }

    useEffect(() => {
        Transport("presets", "list", {'type': type, 'param': param}, handleListResponse, refreshApp);
    }, [preset, isOpen, param]);

    function setPresetValue(id) {
        setPreset(id);
        var i = 0;
        for (i = 0; i < presets.length; i++) {
            if (presets[i]['id'] === id) {
                if (presets[i]['data'] !== undefined && presets[i]['data'] !== "") setValue(JSON.parse(presets[i]['data']));
                break;
            }
        }
    }

    function HandleCreateClicked(event) {
        setRecord({'id': 0, 'name': ""})
        setIsOpen(true);
    }
    function HandleEditClicked(event) {
        var i = 0;
        for (i = 0; i < presets.length; i++) {
            if (presets[i]['id'] === preset) {
                setRecord({'id': presets[i]['id'], 'name': presets[i]['name']});
                setIsOpen(true);
                break;
            }
        }

    }

    function handleDeleteResponse(response) {
        setPreset(0);
    }

    function handleConfirmClicked (event) {
        Transport("presets", "remove", {'id': preset}, handleDeleteResponse, refreshApp);
    }

    const { Dialog, onOpen } = ConfirmationModal({
        headerText: "Group remove confirmation",
        bodyText: "Remove preset " + preset + "?",
        buttonText: "Confirm",
        cancelText: "Cancel",
        handleConfirmFunction: handleConfirmClicked
    });

    function handleDeleteClicked (event) {
        if (preset !== 0) onOpen();
    }

    return (
        <>
            <Dialog/>
            <PresetModal refreshApp={refreshApp} type={type} param={param} isOpen={isOpen} setIsOpen={setIsOpen} setPreset={setPreset} record={record} setRecord={setRecord} value={value}/>
            <InputGroup size={"sm"} className={"mt-1"}>
                <PresetSelect preset={preset} setPreset={setPresetValue} presets={presets}/>
                { preset === 0 ? <></> :
                <>
                    <Button size={"sm"} variant={"outline-primary"} onClick={HandleEditClicked}>Edit</Button>
                    <Button size={"sm"} variant={"outline-danger"} onClick={handleDeleteClicked}>Remove</Button>
                </>
                }
                <Button size={"sm"} variant={"outline-primary"} onClick={HandleCreateClicked}>Create</Button>
            </InputGroup>
        </>
    );
}