import React, {useEffect, useState} from "react";
import Transport from "../../../../transport/Transport";
import {Col, Form, Accordion, FormText, InputGroup, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import PresetsControl from "../../../shared/components/PresetsControl";


export default function TimeDistanceControl({refreshApp, startstop, setStartstop}){

    //const [tickers, setTickers] = useState([])
    function getFormattedDate (timestamp) {
        let date = new Date(timestamp*1000);
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        let formatted_date = year + "-" + month + '-' + day;
        return formatted_date;
    }

    function handleEditStartFunction(event) {
        let update_record = JSON.parse(JSON.stringify(startstop));
        update_record.start  =  Math.floor(parseInt(event.target.valueAsNumber) / 1000);
        setStartstop(update_record);
    }
    function handleEditStopFunction(event) {
        let update_record = JSON.parse(JSON.stringify(startstop));
        update_record.stop  =  Math.floor(parseInt(event.target.valueAsNumber) / 1000);
        setStartstop(update_record);
    }

    return (
        <>
            <Row>
            <Col md={7}>
                <Row>
                    <Col md={5}>
                        <div className="mt-1">
                            <FormText>[start]</FormText>
                            <InputGroup size={"sm"} className="mt-1">
                                <Form.Control type="date" onChange={handleEditStartFunction} value={getFormattedDate(startstop.start)} defaultValue={getFormattedDate(startstop.start)}/>
                            </InputGroup>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="mt-1">
                            <FormText>[stop]</FormText>
                            <InputGroup size={"sm"} className="mt-1">
                                <Form.Control type="date" onChange={handleEditStopFunction}  value={getFormattedDate(startstop.stop)} defaultValue={getFormattedDate(startstop.stop)} />
                            </InputGroup>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="mt-1">
                            <FormText>[duration]</FormText>
                            <InputGroup size={"sm"} className="mt-1">
                                <div className="mt-1">
                                    <div>{Math.ceil((startstop.stop - startstop.start) / 60 / 60 / 24)}</div>
                                </div>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={5} className={"mt-1"}>
                <FormText>[presets]</FormText>
                <PresetsControl refreshApp={refreshApp} type={2} param={0} value={startstop} setValue={setStartstop}/>
            </Col>
            </Row>
        </>
    );

}
