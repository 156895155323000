import React from 'react';
import { Link } from "react-router-dom";

export default function SortHeader ({name, pagination, order, handleChangeSort}) {

    const changeSort = (e) => {
        console.log("Changing ordewr", pagination);
        //e.preventDefault();
        handleChangeSort(order, !pagination.desc);
    }

    return (<Link onClick={changeSort}>{name}
        {(() => {
            if (pagination.order === order) {
                if (pagination.desc)
                    return (<> Down</>)
                else
                    return (<> Up</>)
            }
        })()}
    </Link>)

}

