import {Badge, Col, InputGroup, OverlayTrigger, ProgressBar, Row, Table, Tooltip} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Transport from "../../../../transport/Transport";
import Button from "react-bootstrap/Button";
import { Sparklines } from 'react-sparklines';
import {SparklinesLine} from "react-sparklines";

import BacktestStatsDisplay from "./BacktestStatsDisplay";
import CodeEditor from "../../../shared/components/CodeEditor";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-twilight"
import GeneratorSettingsDisplay from "./GeneratorSettingsDisplay";
import {useNavigate} from "react-router-dom";
import GroupsControl from "../../../shared/components/GroupsControl";
import PresetsControl from "../../../shared/components/PresetsControl";



export default function BacktestTopTable({refreshApp, id}) {

    const object_name = "backtests"

    const [code, setCode] = useState("df.sort_values(by=['profit'], inplace=True, ascending=False)");
    const [record, setRecord] = useState({})
    const [strategies, setStrategies] = useState([])
    const [generatorSettings, setGeneratorSettings] = useState({});
    const navigate = useNavigate();

    function handleListResponse(response) {
        setStrategies(response.list);
        setRecord(response.record);
        setGeneratorSettings(JSON.parse(response.record.generator_settings));
        setGeneratorSettings({});
    }

    function update() {
        Transport(object_name, "backtest_top_list", {'id': id, 'code': code}, handleListResponse, refreshApp);
        //alert("UPD");
    }

    useEffect(() => {
        update();
    }, []);

    function handleAce (name, value) {
        setCode(value);
    }

    const HandleUpdate = () => {
       // setLoading(true);
        update();
    }


    function goUrl2  (e)  {
        console.log("Navigating to " , e);
        let url = "/strategy" + e.target.attributes.url.value;
        console.log("URL", url);
        navigate(url);
    }


    function goUrl  (e) {
        e.preventDefault();
        let url = "/strategy" + e.target.attributes.url.value;
        window.open(url, "_blank");
    }

    return (
        <>
            <hr/>
            <BacktestStatsDisplay record={record}/>

            <hr/>
            <GeneratorSettingsDisplay record={record} settings={generatorSettings}/>
            <hr/>
            <Row>
                <Col md={4}>
                    <Sparklines data={record.chart} height={132}>
                        <SparklinesLine color=""/>
                    </Sparklines>
                </Col>
                <Col md={7}>

                        <CodeEditor
                            name="code"
                            value={code}
                            // defaultValue={code}
                            mode="python"
                            //theme="monokai"
                            className="form-control"
                            onChange={handleAce}
                            //width={`${(window.innerWidth / 2)}px`}
                            width={`100%`}
                            height={`${window.innerHeight / 5}px`}
                            fontSize={"1rem"}
                        />
                    <PresetsControl refreshApp={refreshApp} type={6} param={0} value={code} setValue={setCode}/>
                </Col>
                <Col md={1}>
                    <InputGroup size={"sm"}>
                        <Button variant="outline-primary" onClick={HandleUpdate} >Update</Button>
                    </InputGroup>
                </Col>

            </Row>

            <hr/>

            <Table responsive>
            <tr >
                {(() => {
                    let values = [];
                    if (strategies.length > 0)
                        for(const [key, value] of Object.entries(strategies[0])) {
                            if (key !== 'Unnamed: 0') values.push(
                                <td >
                                    <small><small>{key.substring(0, 1)}</small></small>
                                </td>
                            )
                        }
                    return values;
                })()}
            </tr>
            {strategies.map(strategy => (
                <tr role={"button"}>
                    {(() => {
                        let url = "?bid=" + id;
                        for(const [key, value] of Object.entries(strategy)) {
                            if (key !== 'Unnamed: 0') {
                                url += "&";
                                url += key + "=" + value;
                            }
                        }
                        let values = [];
                        for(const [key, value] of Object.entries(strategy)) {
                            if (key !== 'Unnamed: 0') {
                                let newvalue = value;
                                if (value !== 0) {
                                    if (key === 'profit' || key === 'max_pain' ) {
                                        newvalue = value.toFixed(2);

                                        if (value >= 0)
                                            values.push(
                                                <td url = {url} onClick={goUrl}>
                                                    <OverlayTrigger url = {url} placement="top" overlay={<Tooltip id="tooltip">{key}</Tooltip>}>
                                                    <div  url = {url}>{newvalue}</div>
                                                    </OverlayTrigger>
                                                </td>
                                            ); else values.push(
                                            <td url = {url} onClick={goUrl}>
                                                <OverlayTrigger url = {url} placement="top" overlay={<Tooltip id="tooltip">{key}</Tooltip>}>
                                                <div url = {url} >{newvalue}</div>
                                                </OverlayTrigger>
                                            </td>);


                                    } else  if (key === 'trades') {
                                        values.push(
                                            <td url = {url} onClick={goUrl}>
                                                <OverlayTrigger url = {url} placement="top" overlay={<Tooltip id="tooltip">{key}</Tooltip>}>
                                                <div url = {url} >{value}</div>
                                                </OverlayTrigger>
                                            </td>
                                        );

                                    } else {
                                        values.push(
                                            <td url = {url} onClick={goUrl}>
                                                <OverlayTrigger url = {url} placement="top" overlay={<Tooltip id="tooltip">{key}</Tooltip>}>
                                                <div url = {url} >{value}</div>
                                                </OverlayTrigger>
                                            </td>
                                        );
                                    }
                                } else {
                                    values.push(
                                        <td url = {url}  onClick={goUrl} >
                                            <OverlayTrigger url = {url}  placement="top" overlay={<Tooltip id="tooltip">{key}</Tooltip>}>
                                            <div className="text-secondary" url = {url} >{value}</div>
                                            </OverlayTrigger>
                                        </td>
                                    )
                                }
                            }
                        }
                        return values;
                    })()}


                </tr>
            ))}

            </Table>
        </>
    )


}