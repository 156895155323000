import React, {useState} from "react";
import {Badge, Col, FormControl, FormText, InputGroup, Row, Table} from "react-bootstrap";



export default function GeneratorSettingsDisplay({record, settings}) {
    if (settings === undefined) return (<div>ass</div>);
    let settings_elements = [];
    let header_elements = [];
    for(const [key, value] of Object.entries(settings)) {
        header_elements.push(<><td>{key}</td></>);
        settings_elements.push(<><td>{value}</td></>)
    }
    return (
        <small>
        <Table>
            <tr>

                    <td>Type</td>
                    <td>Source</td>
                    {header_elements}
            </tr>
                <tr>
                    { record.type === 0 ? (<td ><div>Up</div></td>): (<td><div>Down</div></td>)}
                    <td><Badge className="bg-white text-uppercase">{record.sources}</Badge></td>
                    {settings_elements}
                </tr>

        </Table>
        </small>
    );
}