import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import {InputGroup, Pagination, PageItem, Row, Col, FormSelect, FormText, FormControl} from "react-bootstrap";
import GroupSelect from "./GroupSelect";
import ConfirmationModal from "../modals/ConfirmationModal";


export default function BulkActions ({refreshApp, checked, group, setGroup, uncheckAll, type, handleBulkMove, handleBulkDelete})  {

    //const [group, setGroup] = useState(0);
    const handleGroupChange = (group) => {
        setGroup(group);
    }

    function handleMove() {
        if (checked.length > 0) handleBulkMove(group);
    }

    function handleDelete() {
        handleBulkMove(group);
    }
    // const handleReset = () => {
    //     uncheckAll();
    // }

    function handleRemoveClicked() {
        if (checked.length > 0) onOpen();
    }

    const { Dialog, onOpen } = ConfirmationModal({
        headerText: "Bulk indicators remove confirmation",
        bodyText: "Remove " + checked.length + " record(s)?",
        buttonText: "Confirm",
        cancelText: "Cancel",
        handleConfirmFunction: handleBulkDelete
    });

    return (
        <>
            <Dialog/>
                <InputGroup size={"sm"}>
                    <GroupSelect refreshApp={refreshApp} type={type} group={group} setGroup={handleGroupChange}/>
                    <Button variant="outline-primary" onClick={handleMove}>Move {checked.length} records</Button>
                    <Button variant="outline-danger" onClick={handleRemoveClicked}>Remove {checked.length} records</Button>
                    <Button variant="outline-secondary" onClick={handleDelete}>Uncheck {checked.length}</Button>
                </InputGroup>
        </>
    );

}
