import ApexCharts from "apexcharts";
import React from "react";


const default_chart = {
    xaxis: {
        "type": 'datetime',
        "labels": {
            "format": 'dd/MM HH:mm',
            "show": true,
            "style": {
                "colors": '#ffffff'
            }
        }
    },
    chart: {
        type: 'candlestick',
        //id: 'candleschart',
        height: 350,
        animations: {
            enabled: false
        }
    },
    tooltip: {
        theme: 'dark',
        x: {
            format: 'dd MMM yyyy, HH:mm',
        },
    },
    yaxis: [{ "labels": {
            "show": false,
        }}],
    annotations: {
        yaxis: [],
        xaxis: [],
        points:[]},
    series: []
}


function  get_point(x,y,color,text, size = 4) {
let point = {
    "x": x,
    "y": y,
    "marker": {
        "size": size,
        "fillColor": '#ffffff',
        "strokeColor": color,
        "radius": 2,
        "cssClass": 'apexcharts-custom-class'
    },
    "label": {
        "text": text,
    }
}

return point
}

function get_y_priceline(price, name ="Price", color='#f0ff00', labelPosition = "right") {
    let yaxis = {
        "y": price,
        "borderColor": color,
        "size": 5,
        "strokeDashArray": 0,
        "label": {
            "borderColor": color,
            "style": {
                "borderColor": '#000',
                "fillColor": color,
            },
            "text": name,
            "position": labelPosition
        }
    }
    return yaxis
}

function get_x_position_area(open_time, close_time) {
return {
    "x": open_time,
    "x2": close_time,
    "fillColor": "#B3F7CA",
    "label": {
        "text": "OPEN"
    }}
}


function prepare_priceprofit_chart(series, trades) {


    let new_series = []


    let trade_serie = [];
    let new_points = []
    //trade_serie.push([trades[0].open_time, 0]);
    for (let i = 0; i < trades.length; i++) {
        let amount = trades[i].deposit + trades[i].actual_profit - 100;
        if (trades[i].is_open) {
            amount += trades[i].profit;
        }
        trade_serie.push([trades[i].close_time, amount])
        new_points.push(get_point(trades[i]['open_time'], trades[i]['open_price'], "#009855", (i + 1).toString()))
        new_points.push(get_point(trades[i]['close_time'], trades[i]['close_price'], "#e52300", (i + 1).toString()))
    }

    new_series.push({
        'name': 'Profit',
        'data': trade_serie
    })


    console.log("SERIES", series);
    for (let i = 0; i < series.length; i++) {
        let nn = []
        for (let y = 0; y < series[i].length; y++) {
            nn.push([series[i][y][0] * 1000, parseFloat(series[i][y][1])])
        }
        new_series.push({
            'name': 'Price',
            'data': nn //series[i]
        })
        console.log("PUSHED", nn);
    }

    console.log("TOTAL SERIAES", new_series);


    let newchart = JSON.parse(JSON.stringify(default_chart));
    newchart['chart']['type'] = 'line';
    newchart['series'] = new_series;
    newchart['annotations']['points'] = new_points;
    newchart['yaxis'] = [
        {
            "seriesName": "Price",
            "labels": {
                "formatter":  function (val) {
                    return val.toFixed(8)
                },
                "show": true,
            }
        },
        {
            "seriesName": 'Profit',
            "opposite": true,
            "labels": {
                "formatter":  function (val) {
                    return val.toFixed(8)
                },
                "show": true,
            }
        }
    ]
    newchart['stroke'] = {
        "width": 2,
            "curve": 'straight'
    }
    newchart['tooltip'] = {
        theme: 'dark'
    }
    return newchart;

}

export function renderPriceProfitChart(series, trades) {
    console.log("SSSSSSSEE", series);
    let chart = prepare_priceprofit_chart(series, trades);
    let el = document.querySelector("#price_profit_chart");
    if (el !== undefined && el != null) {
        let new_chart = new ApexCharts(el, chart)
        new_chart.render();
    }
}

function prepare_chart(positionChart, take, record, position) {
    // console.log("INDICATORS", indicators);
    let new_charts = [];
    if (record.indicators !== undefined) {
       // alert("INDICATORS OK!");
        for (let y = -1; y < record.indicators.length; y++) {
           // console.log("PARSING", record.indicators[y].scheme);

            let scheme;
            if (y !== -1) scheme = JSON.parse(record.indicators[y].scheme);
            else scheme = {'primary': [], 'secondary': []}

           // console.log("INDICATOR", scheme);
            let newchart = JSON.parse(JSON.stringify(default_chart));
            let newindicatorchart = JSON.parse(JSON.stringify(default_chart));
            newindicatorchart['chart']['id'] = "indicator_" + y;
            newindicatorchart['chart']['type'] = 'line';
            newindicatorchart['chart']['group'] = "ohlc_" + record.id;
            newindicatorchart['yaxis']['floating'] = true;
            newindicatorchart['xaxis']['labels']['show'] = false;
            newindicatorchart['chart']['height'] = 200;
            newchart['chart']['id'] = "ohlc_" + y;
            newchart['chart']['group'] = "ohlc_" + record.id;

            let ohlc = []

            let primary_series = [];
            let primary_points = [];

            let secondary_series = [];
            let secondary_points = [];

            let primary_serie =  {
                name: 'ohlc_' + y.toString(),
                data: []
            }

            for (let i = 0; i < scheme['secondary'].length; i++) {
                if (scheme['secondary'][i]["type"] === "area" || scheme['secondary'][i]["type"] === "line") secondary_series.push({
                    name: scheme['secondary'][i]["name"] ,
                    type: scheme['secondary'][i]["type"],
                    color:  scheme['secondary'][i]["color"],
                    data: []
                });
            }

            let min = 0;
            let max = 0;
            let last_time = 0;
            let last_ind_time = {};

            for (let i =0; i < positionChart.length; i++) {
                if (positionChart[i].closed && y !== -1) {

                    // if (y === 0) {
                    //
                    //     ohlc = [positionChart[i].time, positionChart[i].open,positionChart[i].high,positionChart[i].low,positionChart[i].close]
                    //     if (min === 0 || min > positionChart[i].low) min = positionChart[i].low;
                    //     if (max === 0 || max < positionChart[i].high) max = positionChart[i].high;
                    //     primary_serie.data.push(ohlc);
                    //
                    // } else {
                    let name =   "f" + (y).toString() + "_"


                        ohlc = [positionChart[i][name + "time"] * 1000, positionChart[i][name + "open"],positionChart[i][name + "high"],positionChart[i][name + "low"], positionChart[i][name + "close"]];
                        //     console.log(name, ohlc,  positionChart[i]);
                        if (min === 0 || min > positionChart[i][name + "low"]) min = positionChart[i][name + "low"];
                        if (max === 0 || max < positionChart[i][name + "high"]) max = positionChart[i][name + "high"];
                        if (last_time !== ohlc[0]) {
                            last_time = ohlc[0];
                            primary_serie.data.push(ohlc);
                        }
                    //}

                    for(let ii = 0; ii < scheme['secondary'].length; ii++) {
                        if (scheme['secondary'][ii]["type"] === "area" || scheme['secondary'][ii]["type"] === "line") {
                            name = "f" + (y).toString() + "_";

                            let ind = [positionChart[i][name + "time"] * 1000, positionChart[i][name + scheme['secondary'][ii]["name"]]]
                            if ( last_ind_time[name + scheme['secondary'][ii]["name"]] === undefined || last_ind_time[name + scheme['secondary'][ii]["name"]] !== ind[0]) {
                                last_ind_time[name + scheme['secondary'][ii]["name"]] = ind[0];
                                secondary_series[ii].data.push(ind);
                            }
                          //  console.log("PUTTING", [positionChart[i][name + "time"], positionChart[i][name + scheme['secondary'][ii]["name"]]]);
                        } else if (scheme['secondary'][ii]["type"] === "point") {
                            name = "f" + (y).toString() + "_";

                            if (positionChart[i][name + scheme['secondary'][ii]["name"]]) {
                                secondary_points.push(get_point(positionChart[i][name + "time"] * 1000 , positionChart[i][name + scheme['secondary'][ii]["y"]], scheme['secondary'][ii]['color'],"", scheme['secondary'][ii]["size"]));
                            }
                        }
                    }


                    
                } else if (y === -1) {
                    ohlc = [positionChart[i][ "time"] * 1000, positionChart[i]["open"],positionChart[i]["high"],positionChart[i]["low"], positionChart[i]["close"]];
                    primary_serie.data.push(ohlc);
                }
            }

            //Positions
            newindicatorchart['series'] = secondary_series;
            newindicatorchart['annotations']['points'] = secondary_points;

            newchart['annotations']['yaxis'].push(get_y_priceline(position.open_price, "Price"));
            //newchart['annotations']['yaxis'].push(get_y_priceline(position.open_price + (position.open_price / 100) * take, "Take", "#00c0d0", "left"));
            newchart['annotations']['yaxis'].push(get_y_priceline(position.close_price, "Close", "#ee6500", "left"));
            newchart['annotations']['xaxis'].push(get_x_position_area(position['close_time'], position['open_time']));

            if (position['stop_loss'] !== undefined) {
                newchart['annotations']['yaxis'].push(get_y_priceline(position.stop_loss, "Stop", "#d60027", "right"));
                newchart['yaxis']['min'] = position['take_profit'];
            }

            if (position['take_profit'] !== undefined) {
              //  alert("TAKE FOUND!");
               // alert(position['take_profit']);
                newchart['yaxis']['max'] = position['take_profit'];
                newchart['yaxis']['forceNiceScale'] = true;
                newchart['annotations']['yaxis'].push(get_y_priceline(position.take_profit, "Take", "#00c0d0", "left"));
            }

            let sub_points = []
            for (let k = 0; k < position['sub'].length; k++ ) {
                primary_points.push(get_point(position['sub'][k]['open_time'], position['sub'][k]['open_price'], "#00a976", position['sub'][k]['open_price'] + "[" + position['sub'][k]['volume'] + "]"));
            }
            if (position['close_time'] !== 0) {
                primary_points.push(get_point(position['close_time'], position['close_price'], "#e80000", "Close"));
            }
            newchart.series.push(primary_serie);
            newchart['annotations']['points'] = primary_points;
            new_charts.push(newchart);
            new_charts.push(newindicatorchart);
        }
    }
    console.log("Ready charts", new_charts);
    return new_charts;
}



export function renderCharts(fullChart, take, record, position, rendered) {

    console.log("REEECOORD", position)

    let charts = prepare_chart(fullChart, take, record, position);
    var new_rendered = [];
    let chart;
    console.log("Ren1dered", record);
    for (let i =0 ; i < rendered.length; i++) {
        //console.log("Destroying");
        rendered[i].destroy();
    }

    for (let i =0; i < charts.length; i++) {
       // console.log( charts[i]);
       // alert("RENDERING!")
        try {
            let el = document.querySelector("#" +charts[i]['chart']['id']);
            if (el !== undefined && el != null) {
                chart = new ApexCharts(el, charts[i])
                chart.render();
                new_rendered.append(chart);
            }
        } catch (e) {
        }
    }
    return new_rendered;
}

export default function PositionChartsContainers() {
    let all_charts = [];
    for (let i = -1; i < 10; i++) {
        if (i === -1) {
        all_charts.push(<div id={"ohlc_"}></div>);
        all_charts.push(<div id={"indicator_"}></div>);
        } else {
            all_charts.push(<div id={"ohlc_" + i}></div>);
            all_charts.push(<div id={"indicator_" + i}></div>);
        }
    }
    return all_charts;
}
