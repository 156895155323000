import {Col, Row, Table} from "react-bootstrap";
import {useState} from "react";
import {useLocation} from "react-router-dom";

import {Sparklines, SparklinesLine} from "react-sparklines";
import CodeEditor from "../../shared/components/CodeEditor";
import GrouptestResults from "./components/GrouptestResults";

export default function Grouptest({refreshApp}) {

    let location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    const id = searchParams.get('id');

    return (
        <>
            <div className={"mt-3"}>
                <h5>Grouptest {id}</h5>
            </div>
            <Row>
            <GrouptestResults refreshApp={refreshApp} id={id}/>
            </Row>
        </>
    )

}