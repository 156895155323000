import {Table} from "react-bootstrap";

export default function Records({refreshApp}) {

    const example_records = [
        {'id': 1, 'name': '123'},
        {'id': 2, 'name': 'ass123'},
        {'id': 3, 'name': 'assass123'},
    ]


    return (
        <>
            <div className={"mt-3"}>
                <h5>Records</h5>
                <hr/>
            </div>
            <Table>
                <tbody>
                {
                    example_records.map((record) => (<tr><td>{record.id}</td><td>{record.name}</td></tr>))
                }
                </tbody>
            </Table>
        </>
    )

}