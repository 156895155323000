import Modal from "react-bootstrap/Modal";
import React, {useCallback, useState} from "react";
import {Col, Form, FormControl, FormText, Row} from "react-bootstrap";
import CodeEditor from "../../shared/components/CodeEditor";
import Transport from "../../../transport/Transport";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-twilight"
import GroupSelect from "../../shared/components/GroupSelect";
import Button from "react-bootstrap/Button";
export default function RobotModal({refreshApp, record, setRecord, isOpen, setIsOpen}) {

    function handleDefaultRecordResponse(response) {
        console.log("Default Response", response);
        setRecord(response.record);
        setIsOpen(true);
    }

    function closeModal ()  {
        // Set state to closed
        setIsOpen(false);
    }

    const onValueChange = (key, value) => {
        //Copy current values
        console.log("HANDLE CH", key, value);
        let newRecord = Object.assign({}, record);
        //Set new value by key
        newRecord[key] = value;
        console.log("EDIT", record);
        newRecord[key] = value;
        console.log("NEW EDIT", newRecord);
        //Update values in state
        setRecord(newRecord);
    }

    function handleEditFunction (key) {
        // Add determinated param to event
        return (e) => onValueChange(key, e.target.value);
    }

    function setGroup(newid) {
        onValueChange("gid", parseInt(newid));
    }

    function handleAce (name, value) {
        onValueChange(name, value);
    }

    function handleUpdateResponse(response) {
        setIsOpen(false);
    }

    function handleSaveFunction() {
        let newRecord = Object.assign({}, record);
        Transport("engines", "update", newRecord, handleUpdateResponse, refreshApp);
    }


    return (
            <Modal size="xl" className="modal-lg dark" show={isOpen} onHide={closeModal}>
                {/*// onHide={closeModal}>*/}
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit indicator
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                        {
                            Object.keys(record).map((key, index) =>
                            {

                                switch (key) {
                                    // Do not diplay
                                    case "id":
                                    case "registered":
                                        return <></>

                                    // Display special fields
                                    case 'gid':
                                        return <>
                                            <Col md={6} key={"col_" + key} className={"mt-2"}>
                                                <FormText key={"label_" + key}>[{key}]</FormText>
                                                <GroupSelect refreshApp={refreshApp} type={"engines"} group={record[key]} setGroup={setGroup}/>
                                            </Col>
                                        </>

                                    case 'description':
                                        return <>
                                            <Col md={12} key={"col_" + key} className={"mt-2"}>
                                            <FormText key={"label_" + key}>[{key}]</FormText>
                                                <FormControl key={"input_" + key} onChange={handleEditFunction(key)}  size="sm" as="textarea" rows="7"  name={key} defaultValue={record[key]}/>
                                            </Col>
                                        </>


                                    case 'params':
                                        return <>
                                            <Col md={12} key={"col_" + key} className={"mt-2"}>
                                                <FormText key={"label_" + key}>[{key}]</FormText>
                                                <CodeEditor key={"input_" + key} name={key} value={record[key]} mode="json" onChange={handleAce}  width="100%" height={`${window.innerHeight / 5}px`} fontSize={"1rem"}/>
                                            </Col>
                                        </>

                                    // Display as default(text)
                                    default:
                                        return <>
                                            <Col md={6} key={"col_" + key} className={"mt-2"}>
                                                <FormText key={"label_" + key}>[<strong>{key}</strong>]</FormText>
                                                <FormControl key={"input_" + key} type="text" size="sm" onChange={handleEditFunction(key)} defaultValue={record[key]} className={"mt-1"}/>
                                            </Col>
                                        </>
                                }
                               // return <></>
                            })
                        }
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button size={"sm"} variant="outline-primary" onClick={handleSaveFunction}>Save</Button>
                    <Button size={"sm"} variant="outline-secondary"  onClick={closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        )

}