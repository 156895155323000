import EnginesTable from "./components/EnginesTable";

export default function Engines({refreshApp}) {

    return (
        <>
            <div className={"mt-3"}>
                <h5>Engines</h5>
            </div>
            <EnginesTable refreshApp={refreshApp}/>
        </>
    )

}