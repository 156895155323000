

import React from "react";
import {Badge, Table} from "react-bootstrap";



export default function StrategyFullStats({record, trades}) {


    const toHHMMSS = (secs) => {
        var sec_num = parseInt(secs, 10)
        var hours   = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        // var seconds = sec_num % 60
        if (hours < 10) hours = "0" + hours;
        if (minutes < 10) minutes = "0" + minutes;
        return [hours,minutes].join(":")
    }

    let closed_profit = 0;
    let open_profit = 0;
    let total_time = 0;
    let max_pain = 0;
    let liquidations = 0;
    for (let i = 0; i < trades.length; i++) {
        if (trades[i].is_open) {
            open_profit += trades[i].profit;
        } else {
            closed_profit += trades[i].profit;
        }
        if (trades[i].liquidation === 1) liquidations++;
        total_time += (trades[i].close_time - trades[i].open_time) / 1000;
        if (max_pain > trades[i].min_profit) max_pain = trades[i].min_profit;
    }
    console.log("RRREECC1", record)
    let avg_duration = toHHMMSS(total_time / trades.length);
    let avg_profit = (open_profit + closed_profit) / trades.length


    return (
        <Table>
                <tr>
                    <td><div>Type</div></td>
                    <td><div>Sources</div></td>
                    <td><div>Profit</div></td>
                    <td><div>Trades</div></td>
                    <td><div>Average profit</div></td>
                    <td><div>Average duraton</div></td>
                    <td><div>Max pain</div></td>
                    <td><div>Liquidations</div></td>
                </tr>

               <tr>
                    { record.type === 0 ? (<td><div>Up</div></td>): (<td><div>Down</div></td>)}
                    <td><Badge className="bg-white text-black text-uppercase">{record.sources}</Badge></td>
                    <td><div>{closed_profit.toFixed(2)} ({open_profit.toFixed(2)})</div></td>

                    { trades[trades.length - 1].is_open === 0 ?
                        (<td><div>{trades.length}</div></td>):
                        (<td><div>{trades.length - 1} ({trades.length})</div></td>)
                    }
                    {   avg_profit >= 0 ? (
                        <td><div>{avg_profit.toFixed(2)}</div></td>
                        ):(
                        <td><div>{avg_profit.toFixed(2)}</div></td>
                    )}

                    <td><div>{avg_duration}</div></td>
                    <td><div>{max_pain}</div></td>
                    <td><div>{liquidations}</div></td>
               </tr>


        </Table>
    );
}