import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {InputGroup, Pagination, PageItem, Row, Col, FormSelect, FormText, FormControl} from "react-bootstrap";
import Transport from "../../../transport/Transport";

export default function PresetSelect({preset, setPreset, presets}){

    const handlePresetChange = (event) => {
        setPreset(parseInt(event.target.value));
    }

    return (
         <FormSelect onChange={handlePresetChange} value={preset}  size="sm">
             <option value="0">[No preset]</option>
                            {presets.map(item => (
                                    <option value={item.id}>{item.name}</option>
                            ))}
         </FormSelect>
    );

}