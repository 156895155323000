import React, {useState} from "react";
import {Col, FormControl, FormText, InputGroup, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";

export default function StrategySettingsControl({record, settings}) {


    let settings_elements = [];

    const [editfields, setEditfields] = useState(settings);
    const navigate = useNavigate();


    const hangleChange = (e) => {
        let name = e.target.attributes.name.value;
        let val = e.target.value;
        let newEditFields = JSON.parse(JSON.stringify(editfields));
        let new_value = "";
        const allowed = ['-', '0','1','2','3','4','5','6','7','8','9','.'];
        for (let i = 0; i < val.length; i++) {
            if (allowed.includes(val[i])) new_value += val[i];
        }
        newEditFields[name] = new_value;
        setEditfields(newEditFields)
    }


    const handleUpdate = () => {
        let url = "/strategy?bid=" + record.id;
        for(const [key, value] of Object.entries(editfields)) {
            if (key !== 'Unnamed: 0') {
                url += "&";
                url += key + "=" + value;
            }
        }
        navigate(url);
        window.location.reload();

    }
    for(const [key, value] of Object.entries(editfields)) {
            settings_elements.push(<>
            <Col md={1} className="mt-2">
                <FormText>{key}</FormText>
                <InputGroup className="input-group-sm">
                    <FormControl name={key} value={editfields[key]}  defaultValue={editfields[key]} onChange={hangleChange}/>
                </InputGroup>
            </Col>
        </>)
    }



    return (
        <Row className="mb-3">
            {settings_elements}
            <Col md={1} className="mt-2">
                <FormText>Reload</FormText>
                <InputGroup className="input-group-sm">
                    <Button variant="outline-primary" className="btn-sm" onClick={handleUpdate}>Update</Button>
                </InputGroup>
            </Col>
        </Row>
    );
}