import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {InputGroup, Pagination, PageItem, Row, Col, FormSelect, FormText, FormControl} from "react-bootstrap";
import Transport from "../../../transport/Transport";

export default function GroupSelect({refreshApp, type, group, setGroup}){

    const [groups, setGroups] = useState([])

    const handleGroupChange = (event) => {
        setGroup(parseInt(event.target.value));
    }

    function handleListResponse(response) {
        setGroups(response.list);
        console.log("Groups response", response);
    }

    useEffect(() => {
        Transport("groups", "list", {'type': type}, handleListResponse, refreshApp);
    }, [group]);


    return (
         <FormSelect onChange={handleGroupChange} value={group}  size="sm">
             <option value="0">[No group]</option>
                            {groups.map(item => (
                                    <option value={item.id}>{item.name}</option>
                            ))}
         </FormSelect>
    );

}