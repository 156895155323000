import React, {usestats} from "react";
import {Table} from "react-bootstrap";


export default function BacktestStatsDisplay({record}) {
   // console.log("Record", record);
    if (record.stats === undefined) return (<></>);
   // console.log("STATSSSS!!", record.stats);
    return (
            <Table>
                <tr>
                <td>Size</td>
                <td>Profit min</td>
                <td>Profit max</td>
                <td>Pain min</td>
                <td>Pain max</td>
                <td>Trades min</td>
                <td>Trades max</td>
                <td>Liq min</td>
                <td>Liq max</td>
                </tr>
            <tr>
                <td>{record.stats.size}</td>
                <td>{record.stats.profit_min}</td>
                <td>{record.stats.profit_max}</td>
                <td>{record.stats.min_min}</td>
                <td>{record.stats.min_max}</td>
                <td>{record.stats.trades_min}</td>
                <td>{record.stats.trades_max}</td>
                <td>{record.stats.liquidations_min}</td>
                <td>{record.stats.liquidations_max}</td>
            </tr>
            <tr>
                <td>       {(() => {
                                    // Convert registered timestamp to string
                                    return new Date(record.start*1000).toLocaleString();
                                })()}

                </td>
                <td>       {(() => {
                                    // Convert registered timestamp to string
                                    return new Date(record.stop*1000).toLocaleString();
                                })()}

                </td>
            </tr>
            </Table>
    );
}