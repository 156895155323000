export default function Transport(object, method, data, handler = undefined, refreshApp = undefined) {

    // Get token from local storage
    const token = localStorage.getItem('flapary_token');

    // Fill protocol fields
    const PostProtoFields = {
        token: token,
        object: object,
        method: method,
        data: data
    }

    // Prepare fetch oprions
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'text/plain' },
        body: JSON.stringify(PostProtoFields)
    };

    console.log("Posting", requestOptions.body);
    //Do request
    console.log(requestOptions)
    fetch('https://lab.algovu.net/api', requestOptions)
        .then(res => res.json())
        .then(json => {

            console.log("JJJJ", json)

            // Check if token is dead
            if (json.auth_needed !== undefined) {
                //Token is dead. Remove token from local storage and refresh app to get login screen component
                localStorage.removeItem('flapary_token');
                if (refreshApp !== undefined) refreshApp();
            } else {
                //No errors found. Pass data to handler
                handler(json);
            }
    })
}