import Modal from "react-bootstrap/Modal";
import React, {useCallback, useState} from "react";
import {Col, Form, FormControl, FormText, InputGroup, Row} from "react-bootstrap";
import CodeEditor from "../../../shared/components/CodeEditor";
import Transport from "../../../../transport/Transport";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-twilight"
import GroupSelect from "../../../shared/components/GroupSelect";
import Button from "react-bootstrap/Button";
import TimeFramesControl from "../components/TimeframesControl";
export default function GrouptestModal({refreshApp, record, setRecord, isOpen, setIsOpen}) {

    function handleDefaultRecordResponse(response) {
        console.log("Default Response", response);
        setRecord(response.record);
        setIsOpen(true);
    }

    function closeModal ()  {
        // Set state to closed
        setIsOpen(false);
    }

    const onValueChange = (key, value) => {
        //Copy current values
        console.log("HANDLE CH", key, value);
        let newRecord = Object.assign({}, record);
        //Set new value by key
        newRecord[key] = value;
        console.log("EDIT", record);
        newRecord[key] = value;
        console.log("NEW EDIT", newRecord);
        //Update values in state
        setRecord(newRecord);
    }

    function handleEditFunction (key) {
        // Add determinated param to event
        return (e) => onValueChange(key, e.target.value);
    }

    function setGroup(newid) {
        onValueChange("gid", parseInt(newid));
    }

    function handleUpdateResponse(response) {
        setIsOpen(false);
    }

    function handleSaveFunction() {
        let newRecord = Object.assign({}, record);
        Transport("grouptests", "update", newRecord, handleUpdateResponse, refreshApp);
    }

    function setComment(event) {
        let newRecord = Object.assign({}, record);
        newRecord['comment'] = event.target.value;
        setRecord(newRecord);
    }


    function setTimeframes(timeframes) {
        let newRecord = Object.assign({}, record);
        newRecord['timeframes'] = timeframes;
        setRecord(newRecord);
    }

    function setRecordGroup(newgroup) {
        let newRecord = Object.assign({}, record);
        newRecord['target_gid'] = newgroup;
        setRecord(newRecord);
    }

    return (
            <Modal size="xl" className="modal-lg dark" show={isOpen} onHide={closeModal}>
                {/*// onHide={closeModal}>*/}
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        New group test
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>

                            <Col md={10}>
                                <div className="mt-1">
                                    <FormText>[comment]</FormText>
                                    <InputGroup size={"sm"} className="mt-1">
                                        <FormControl onChange={setComment}  type="text" maxLength={100}  name="comment" value={record.comment}/>
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className="mt-1">
                                    <FormText>[gid]</FormText>
                                    <InputGroup size={"sm"} className="mt-1">
                                        <GroupSelect refreshApp={refreshApp} type={"grouptests"} group={record['gid']} setGroup={setGroup}/>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        <hr/>
                        <TimeFramesControl timeframes={record['timeframes']} setTimeframes={setTimeframes}/>
                        <hr/>
                        <Row>
                            <Col md={4}>
                                <div className="mt-1">
                                    <FormText>[target_gid]</FormText>
                                    <InputGroup size={"sm"} className="mt-1">
                                        <GroupSelect refreshApp={refreshApp} type={"robots"} group={record['target_gid']}  setGroup={setRecordGroup}/>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button size={"sm"} variant="outline-primary" onClick={handleSaveFunction}>Create group test</Button>
                    <Button size={"sm"} variant="outline-secondary"  onClick={closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        )

}