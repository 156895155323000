import {PageItem, Pagination} from "react-bootstrap";
import React from 'react';

export default function CustomPagination({pagination, handleChangePage}) {
    const changePage = (event) => {
        if (event.target.attributes.page !== undefined) {
            handleChangePage(parseInt(event.target.attributes.page.value));
        }
    }
    //var total_pages = state
    var total_pages = pagination.total / pagination.count;
    if (total_pages <= 1)  return (<div></div>);
    return (
        <>
            <Pagination size="sm">
                {(() => {
                    var rows = [];
                    if (total_pages > 1) {
                        var last_ok = true;
                        var i = 0;
                        if (pagination.page !== undefined && pagination.page > 0) {
                            rows.push(<PageItem  page={pagination.page - 1}  onClick={changePage}>Prev</PageItem>);
                        } else {
                           // rows.push(<PageItem page={pagination.page - 1}  active={true}>Prev</PageItem>);
                        }
                        for (i = 0; i < total_pages; i++) {
                            var allowed = false;
                            if (pagination.page === i) allowed = true;
                            if (total_pages < 5) {
                                allowed = true;
                            } else {
                                if (i < 7) allowed = true;
                                else if (i > total_pages - 5) allowed = true;
                                else if (i >= pagination.page - 2 && i <= pagination.page + 2) allowed = true;
                            }
                            if (!allowed && last_ok) {
                                rows.push(<PageItem page={i}  onClick={changePage}>..</PageItem>);
                            }
                            last_ok = allowed;
                            if (allowed) {
                                if (pagination.page === i) {
                                    rows.push(<PageItem page={i} active onClick={changePage}>{i + 1}</PageItem>);
                                } else {
                                    rows.push(<PageItem page={i} onClick={changePage}>{i + 1}</PageItem>);
                                }
                            }
                        }
                        if (pagination.page !== undefined && pagination.page < total_pages - 1) {
                            rows.push(<PageItem page={pagination.page + 1}  onClick={changePage}>Next</PageItem>);
                        } else {
                           // rows.push(<PageItem page={pagination.page + 1}  active>Next</PageItem>);
                        }
                    }
                    return rows;
                })()}
            </Pagination>
        </>)

}


