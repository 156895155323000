import React, { useState } from 'react';

import {
    FormLabel,
    Button,
    Form,
    FormGroup,
    Container,
    FormControl,
    Alert,
    Row, Card, Col
} from "react-bootstrap";

import Transport from "../../transport/Transport";

export default function Login({refreshApp}) {

    // State to keep username and password
    const [values, setValues] = useState({});

    // State to keep error, returned by server
    const [error, setError] = useState();

    // Handle server api auth response
    const handleAuthResponse = (response) => {

        // Log response to console
        console.log(response);

        if (response['error'] !== undefined) {
            // Got error in response. Setting it to state.
            setError(response['error']);

        } else if (response['token'] !== undefined) {

            //Got token in response. Auth success. Setting token to local storage
            localStorage.setItem('flapary_token', response['token']);

            // Call parent component refresh
            refreshApp();
        }

    }

    // Submit button handler
    const handleSubmit = async e => {

        // Stop default form action execution
        e.preventDefault();

        // Make auth request
        Transport("auth", "login", values, handleAuthResponse, refreshApp);
    }

    const onValueChange = (key, value) => {

        //Copy current values
        let newValues = Object.assign({}, values);

        //Set new value by key
        newValues[key] = value;

        //Update values in state
        setValues(newValues);
    }

    const handleChange = (key) => {
        // Add determinated param to event
        return (e) => onValueChange(key, e.target.value);

    }

    return(
        <Container>
            <Row className="justify-content-center">
                <Col xs={10} md={4}>
                    <Card className="my-5 px-5 pb-4 pt-2">
                        <h3 className="m-3 text-center">Sign in</h3>
                        <Form onSubmit={handleSubmit}>

                            {/*Display error*/}
                            { error ? <Alert variant="danger">{error}</Alert> : null }
                            {/*----------*/}

                            <FormGroup className="mt-3">
                                <FormLabel>Username</FormLabel>

                                {/*Username field*/}
                                <FormControl type="text" className="form-control-sm" onChange={handleChange('username')} />
                                {/*----------*/}

                            </FormGroup>

                            <FormGroup className="mt-3">
                                <FormLabel>Password</FormLabel>

                                {/*Password field*/}
                                <FormControl type="password" className="form-control-sm" onChange={handleChange('password')} />
                                {/*----------*/}

                            </FormGroup>

                            <div className="mt-3 text-center">
                                <Button variant="outline-primary" className="btn btn-sm" type="submit">Login</Button>
                            </div>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}