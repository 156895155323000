import RobotsTable from "./RobotsTable";

export default function Robots({refreshApp}) {

    return (
        <>
            <div className={"mt-3"}>
                <h5>Robots</h5>
            </div>
            <RobotsTable refreshApp={refreshApp}/>
        </>
    )

}