import Modal from "react-bootstrap/Modal";
import React from "react";
import {Col, Form, FormControl, FormText, InputGroup, Row} from "react-bootstrap";

import Transport from "../../../../transport/Transport";


import GroupSelect from "../../../shared/components/GroupSelect";
import Button from "react-bootstrap/Button";
import TickersSelectButtons from "../components/TickersSelectButtons";
import TimeFramesIndicatorsControl from "../components/TimeframesIndicatorsControl";
import TimeDistanceControl from "../components/TimeDistanceControl";
import TypeSelect from "../components/TypeSelect";
import EnginesSettingsControl from "../components/EngineSettingsControl";

export default function BacktestModal({refreshApp, record, setRecord, isOpen, setIsOpen}) {


   // const [record, setRecord] = useState(preloadedRecord)
    function getTickers() {
        if (record['tickers'] === undefined) return [];
        return record['tickers'];
    }

    function setTickers(tickers) {
        let newRecord = Object.assign({}, record);
        newRecord['tickers'] = tickers;
        setRecord(newRecord);
    }

    function setType(type) {
        let newRecord = Object.assign({}, record);
        newRecord['type'] = type;
        setRecord(newRecord);
    }

    function setComment(event) {
        let newRecord = Object.assign({}, record);
        newRecord['comment'] = event.target.value;
        setRecord(newRecord);
    }

    function getGroup() {
        if (record['gid'] === undefined) return 0;
        return record['gid'];
    }

    function setGroup(group) {
        let newRecord = Object.assign({}, record);
        newRecord['gid'] = group;
        setRecord(newRecord);
    }

    function getFramesIndicators() {
        if (record['indicators'] === undefined) return [];
        return record['indicators'];
    }

    function setFramesIndicators(indicators) {
        let newRecord = Object.assign({}, record);
        newRecord['indicators'] = indicators;
        setRecord(newRecord);
    }

    function getStartstop() {
        if (record['start'] === undefined) return {'start': Math.floor(Math.floor(Date.now() / 1000) - 60 * 60 * 24 * 90), 'stop': Math.floor(Math.floor(Date.now() / 1000))};
        return {'start': record.start, 'stop': record.stop}
    }

    function setStartstop(startstop) {
        let newRecord = Object.assign({}, record);
        newRecord['start'] = startstop.start;
        newRecord['stop'] = startstop.stop;
        setRecord(newRecord);
    }

    function closeModal ()  {
        // Set state to closed
        setIsOpen(false);
    }


    function handleUpdateResponse(response) {
        setIsOpen(false);
    }
    //
    function handleSaveFunction() {
        let newRecord = Object.assign({}, record);
        Transport("backtests", "update", newRecord, handleUpdateResponse, refreshApp);
    }

    if (record.start === 0 || record.stop === 0) {
        if (record.start === 0) record.start  =  Math.floor(Math.floor(Date.now() / 1000) - 60 * 60 * 24 * 90);
        if (record.stop === 0) record.stop  =  Math.floor(Math.floor(Date.now() / 1000));
        let update_record = JSON.parse(JSON.stringify(record));
        setRecord(update_record);
    }


    function handleChangeSettings(settings) {
        console.log("SEtting settings", settings);
        let newRecord = Object.assign({}, record);
        newRecord['generator_settings'] = settings.params;
        newRecord['engine'] = settings.id;
        setRecord(newRecord);
    }



    return (
            <Modal size="xl" className="modal-lg pb-5" show={isOpen} onHide={closeModal}>
                {/*// onHide={closeModal}>*/}
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create backtest
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={2}>
                                <div className="mt-1">
                                    <FormText>[type]</FormText>
                                    <InputGroup size={"sm"} className="mt-1">
                                        <TypeSelect handleChange={setType} defaultValue={record.type}/>
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col md={8}>
                                <div className="mt-1">
                                    <FormText>[comment]</FormText>
                                    <InputGroup size={"sm"} className="mt-1">
                                    <FormControl onChange={setComment}  type="text" maxLength={100}  name="comment" value={record.comment}/>
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className="mt-1">
                                    <FormText>[gid]</FormText>
                                    <InputGroup size={"sm"} className="mt-1">
                                       <GroupSelect refreshApp={refreshApp} type={"backtests"} group={getGroup()} setGroup={setGroup}/>
                                    </InputGroup>
                                </div>
                            </Col>

                        </Row>
                        <hr/>
                        <TickersSelectButtons refreshApp={refreshApp} selected={getTickers()} setSelected={setTickers}/>
                        <hr/>
                        <TimeFramesIndicatorsControl refreshApp={refreshApp} frameindicator={getFramesIndicators()} setFrameindicator={setFramesIndicators}/>
                        <hr/>
                        <TimeDistanceControl refreshApp={refreshApp} startstop={getStartstop()} setStartstop={setStartstop}/>
                        <hr/>
                        <EnginesSettingsControl refreshApp={refreshApp} changeEngine={handleChangeSettings} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button size={"sm"} variant="o1utline-primary" onClick={handleSaveFunction}>Create</Button>
                    <Button size={"sm"} variant="outline-secondary"  onClick={closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        )

}