import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {InputGroup, Pagination, PageItem, Row, Col, FormSelect, FormText, FormControl} from "react-bootstrap";
import Transport from "../../../transport/Transport";

export default function EngineSelect({engines, engine, setEngine}){

    const handleEngineChange = (event) => {
        console.log("GOT EVENT", event);
        setEngine(parseInt(event.target.value));
    }

    return (
        <FormSelect onChange={handleEngineChange} value={engine}  size="sm">
            <option value="0">[No engine]</option>
            {engines.map(item => (
                <option value={item.id}>{item.name}</option>
            ))}
        </FormSelect>
    );

}