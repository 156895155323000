import React from "react";

export default function NewPositionLine({trade, index, openPositionModal}) {

    let values = [];
    let sub_values = [];

    const toHHMMSS = (secs) => {
        var sec_num = parseInt(secs, 10)
        var hours   = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
       // var seconds = sec_num % 60

        if (hours < 10) hours = "0" + hours;
        if (minutes < 10) minutes = "0" + minutes;

        return [hours,minutes].join(":")

    }
    values.push(<td className="" key={"index_wrapper" + index}  val={index} onClick={openPositionModal}>
        <div key={"index" + index} val={index}>{index + 1}</div>
    </td>)
    values.push(<td className="" key={"open_price_wrapper" + index}  val={index} onClick={openPositionModal}>
        <div key={"open_price" + index} val={index}>{trade.open_price}</div>
    </td>)
    values.push(<td className="" key={"close_price_wrapper" + index}  val={index} onClick={openPositionModal}>
        <div key={"close_price" + index} val={index}>{trade.close_price}</div>
    </td>)


    values.push(<td className=" text-secondary" key={"open_time_wrapper" + index}  val={index} onClick={openPositionModal}>
        <div key={"open_time" + index} val={index}> {(() => {
            return new Date(trade.open_time).toUTCString().replace("GMT","");;
        })()}</div>
    </td>)
    values.push(<td className=" text-secondary" key={"close_time_wrapper" + index}  val={index} onClick={openPositionModal}>
        <div key={"close_time" + index} val={index}> {(() => {
            return new Date(trade.close_time ).toUTCString().replace("GMT","");
        })()}</div>
    </td>)
    let duration = trade.close_time - trade.open_time;
    let formatted = toHHMMSS(duration / 1000); //new Date(duration).toISOString().substring(11, 16)
    if (duration / 1000 > 60 * 60 * 24) {
        values.push(<td className="  text-danger" key={"duration_wrapper" + index}  val={index} onClick={openPositionModal}>
        <div key={"duration" + index} val={index}> {formatted}</div>
        </td>)
    } else {
        values.push(<td className="" key={"duration_wrapper" + index}  val={index} onClick={openPositionModal}>
            <div key={"duration" + index} val={index}> {formatted}</div>
        </td>)
    }
    values.push(<td className="" key={"volume_wrapper" + index}  val={index} onClick={openPositionModal}>
        <div key={"volume" + index} val={index}> {trade.volume}</div>
    </td>)


    if (trade.liquidation) {

        values.push(<td className=" text-black bg-danger" key={"profit_wrapper" + index}  val={index} onClick={openPositionModal}>
            <div key={"profit" + index} val={index}>{trade.profit}</div>
        </td>)
    } else {
        if (trade.profit >= 0) {
            values.push(<td className=" text-success" key={"profit_wrapper" + index} val={index}
                             onClick={openPositionModal}>
                <div key={"profit" + index} val={index}>{trade.profit}</div>
            </td>)
        } else {
            values.push(<td className=" text-danger" key={"profit_wrapper" + index} val={index}
                             onClick={openPositionModal}>
                <div key={"profit" + index} val={index}>{trade.profit}</div>
            </td>)
        }
    }
    values.push(<td className=" text-danger" key={"min_profit_wrapper" + index}  val={index} onClick={openPositionModal}>
        <div key={"min_profit" + index} val={index}>{trade.min_profit}</div>
    </td>)

    values.push(<td className=" text-success" key={"deposit_wrapper" + index}  val={index} onClick={openPositionModal}>
        <div key={"deposit" + index} val={index}>{trade.deposit}</div>
    </td>)

    values.push(<td className=" text-warning" key={"actual_profit_wrapper" + index}  val={index} onClick={openPositionModal}>
        <div key={"actual_profit" + index} val={index}>{trade.actual_profit}</div>
    </td>)

    values.push(<td className=" text-danger" key={"liquidation_wrapper" + index}  val={index} onClick={openPositionModal}>
        <div key={"liquidation" + index} val={index}>{trade.liquidation}</div>
    </td>)

    values.push(<td className=" text-primary" key={"trades_wrapper" + index}  val={index} onClick={openPositionModal}>
        <div key={"trades" + index} val={index}>{trade.sub.length}</div>
    </td>)
    values.push(<td className="" key={"reason_wrapper" + index}  val={index} onClick={openPositionModal}>
        <div key={"reason" + index} val={index}>{trade.reason}</div>
    </td>)
    if (trade.stop_loss !== undefined) {
        values.push(<td className="" key={"sl_wrapper" + index}  val={index} onClick={openPositionModal}>
            <div key={"sl" + index} val={index}>{trade.stop_loss}</div>
        </td>)
    }
    if (trade.take_profit !== undefined) {
        values.push(<td className="" key={"sl_wrapper" + index}  val={index} onClick={openPositionModal}>
            <div key={"sl" + index} val={index}>{trade.take_profit}</div>
        </td>)
    }


    for (let i = 0; i < trade['sub'].length; i++) {
        let duration = trade.close_time - trade['sub'][i].open_time;
        let formatted = toHHMMSS(duration / 1000);

        sub_values.push(
        <tr val={index} onClick={openPositionModal}>

            <td className="" key={"index_wrapper" + index}  val={index} onClick={openPositionModal} nowrap={"nowrap"}>
                <div key={"index" + index} val={index}>{index + 1} - {i + 1}</div>
            </td>

            <td className="" val={index} onClick={openPositionModal}>
                 <div val={index}>{trade['sub'][i].open_price}</div>
            </td>
            <td className="" val={index} onClick={openPositionModal}>
                <div val={index}>{trade['sub'][i].close_price}</div>
            </td>
            <td className=" text-secondary" val={index} onClick={openPositionModal}>
            <div className="bg-yellow" val={index}> {(() => {
                return new Date(trade['sub'][i].open_time ).toUTCString().replace("GMT","");;
            })()}</div>
             </td>
         <td className=" text-secondary" val={index} onClick={openPositionModal}>
                <div className="bg-yellow" val={index}> {(() => {
                    return new Date(trade.close_time ).toUTCString().replace("GMT","");;
                })()}</div>
         </td>
         <td className=" text-secondary" val={index} onClick={openPositionModal}>
            <div val={index}> {formatted} </div>
         </td>
            <td className=" text-secondary" val={index} onClick={openPositionModal}>
                <div val={index}> {trade['sub'][i].volume} </div>
            </td>
            <td className=" text-secondary" val={index} onClick={openPositionModal}>
                <div val={index}> {trade['sub'][i].profit} </div>
            </td>
     </tr>);

    }

    if (trade.liquidation === 1) {
        return (
            <>
                <tr val={index} onClick={openPositionModal} role="button">
                    {values}
                </tr>
                {sub_values}
            </>
        );
    } else if (trade.is_open === 1) {
        return (
         <>
         <tr val={index} onClick={openPositionModal} role="button">
            {values}
         </tr>
            {sub_values}
         </>
        );
    } else {
        return (
            <>
            <tr val={index} onClick={openPositionModal} role="button">
                {values}
            </tr>
                {sub_values}

        </>);
   }

}