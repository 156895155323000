import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import {InputGroup, Pagination, PageItem, Row, Col, FormSelect, FormText, FormControl} from "react-bootstrap";

export default function IndicatorSelect({indicators, handleChange, index, value}){
    function handleIndicatorChange (event) {
        let val = parseInt(event.target.value);
        let index = event.target.attributes.index.value;
        console.log("Changin", index, val);
        handleChange(index,val);
    }
    return (
         <FormSelect onChange={handleIndicatorChange} value={value} index={index} className="custom_mr-2">
             <option value="0">Select indicator</option>
                            {indicators.map(item => (
                                    <option value={item.id}>{item.name}</option>
                            ))}
         </FormSelect>
    );

}