import {Col, InputGroup, Row, Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import Transport from "../../../transport/Transport";
import Button from "react-bootstrap/Button";
import IndicatorModal from "./IndicatorModal";
import GroupsControl from "../../shared/components/GroupsControl";
import CustomPagination from "../../shared/components/CustomPagination";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import SortHeader from "../../shared/components/SortHeader";
import ConfirmationModal from "../../shared/modals/ConfirmationModal";
import BulkActions from "../../shared/components/BulkActions";
import CountSelect from "../../shared/components/CountSelect";

export default function IndicatorsTable({refreshApp}) {

    // Main settings
    const object_name = "indicators"
    const checkboxes_checked_name = "checked_indicators"
    const pagination_name = "pagination_indicators"

  //  localStorage.removeItem(checkboxes_checked_name);
  //  localStorage.removeItem(pagination_name);

    // Loading from storage
    let loaded_pagination = {};
    let temp = localStorage.getItem(pagination_name)
    if (temp !== "undefined") {
        let get_pagination = JSON.parse(temp);
        if (get_pagination != null) loaded_pagination = get_pagination;

    }
    console.log("LOADED PAA", loaded_pagination);
    let loaded_checked = [];
    temp = localStorage.getItem(checkboxes_checked_name)
    if (temp !== "undefined") {
        let get_checked = JSON.parse(temp);
        if (get_checked != null) loaded_checked = get_checked;
    }

    console.log("PAGINATION", loaded_pagination)

    // For list display
    const [records, setRecords] = useState([]);
    const [pagination, setPagination] = useState(loaded_pagination);

    // For edit in modal
    const [record, setRecord] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    // For check items
    const [checked, setChecked] = useState(loaded_checked);

    // For bulk actions
    const [bulkGroup, setBulkGroup] = useState(loaded_checked);

    // Main list render
    function handleListResponse(response) {
        setRecords(response.list);
        setPagination(response.pagination);
        localStorage.setItem(pagination_name, JSON.stringify(response.pagination));
    }

    function update(pagination) {
        Transport(object_name, "list", pagination, handleListResponse, refreshApp);
    }

    useEffect(() => {
        update(pagination);
    }, [isOpen]);

    // Group update
    function handleChangeGroup(newGroup) {
        let newPagination = Object.assign({}, pagination);
        newPagination['group'] = newGroup;
        setBulkGroup(newGroup);
        setPagination(newPagination);
        update(newPagination);
    }

    // Crud
    function handleRecordResponse(response) {
        response.record.gid = pagination.group;
        setRecord(response.record);
        setIsOpen(true);
    }

    function HandleCreateClicked(event) {
        Transport(object_name, "default", {}, handleRecordResponse, refreshApp);
    }

    // Pages
    function handleChangePage(newPage) {
        let newPagination = Object.assign({}, pagination);
        newPagination['page'] = newPage;
        setPagination(newPagination);
        update(newPagination);
    }

    // Checkboxes
    const handleCheckboxChange = (data) => {
        const isChecked = checked.some(checkedCheckbox => checkedCheckbox === data)
        if (isChecked) {
            setChecked(checked.filter((checkedCheckbox) => checkedCheckbox !== data));
            localStorage.setItem(checkboxes_checked_name, JSON.stringify(checked.filter((checkedCheckbox) => checkedCheckbox !== data)));
        } else {
            setChecked(checked.concat(data));
            localStorage.setItem(checkboxes_checked_name, JSON.stringify(checked.concat(data)));
        }

    };

    const isAllCheckboxesChecked = () => {
        if (records.length === 0) return false;
        for (let i = 0; i < records.length; i++) {
            if (!checked.includes(records[i].id)) {
                return false;
            }
        }
        return true;
    }

    const isOneCheckboxesChecked = (id) => {
        return checked.includes(id);
    }

    const checkAllCheckboxes = (e) => {
        let i = 0;
        let state = e.target.checked;
        let new_checked = JSON.parse(JSON.stringify(checked));
        if (state) {
            for (i = 0; i < records.length; i++) {
                if (!new_checked.includes(records[i].id)) {
                    new_checked = new_checked.concat(records[i].id);
                    setChecked(new_checked);
                }
            }
        } else {
            for (i = 0; i < records.length; i++) {
                new_checked = new_checked.filter(item => item !== records[i].id)
                setChecked(new_checked);

            }
        }
        localStorage.setItem(checkboxes_checked_name, JSON.stringify(new_checked));
    }

    function uncheckAll() {
        setChecked([]);
        localStorage.setItem(checkboxes_checked_name, JSON.stringify([]));
    }

    // Sort
    const handleChangeSort = (sort, desc) => {
        let newPagination = Object.assign({}, pagination);
        newPagination.order = parseInt(sort);
        newPagination.desc = desc;
        setPagination(newPagination);
        update(newPagination);
    }

    const cutText = (text) => {
        if (text.length > 60) text = text.substring(0, 60) + "...";
        return text;
    }

    //Delete
    function handleUpdatedResponse(reponse) {
        update(pagination);
    }

    function handleConfirmClicked (event) {
        Transport("indicators", "remove", {'id': record.id}, handleUpdatedResponse, refreshApp);
    }

    const { Dialog, onOpen } = ConfirmationModal({
        headerText: "Indicator remove confirmation",
        bodyText: "Remove indicator " + record.id + "?",
        buttonText: "Confirm",
        cancelText: "Cancel",
        handleConfirmFunction: handleConfirmClicked
    });


    // Edit
    function HandleEditClicked(event) {
        Transport(object_name, "get", {'id': parseInt(event.target.value)}, handleRecordResponse, refreshApp);
    }


    function handleDeleteClicked (event) {
        setRecord({'id': parseInt(event.target.value)});
        onOpen();
    }

    // Bulk actions
    function handleBulkMove(group) {
        Transport(object_name, "bulk_update", {'bulk': checked, 'group': group}, handleUpdatedResponse, refreshApp);
        uncheckAll();
    }

    function handleBulkDelete() {
        Transport(object_name, "bulk_remove", {'bulk': checked}, handleUpdatedResponse, refreshApp);
        uncheckAll();
    }

    // Display count
    function handleCountChange(newCount) {
        let newPagination = Object.assign({}, pagination);
        newPagination.count =newCount;

        setPagination(newPagination);
        update(newPagination);
    }

    return (
        <>
            <hr/>
            <Dialog/>
            <IndicatorModal refreshApp={refreshApp} record={record} setRecord={setRecord} isOpen={isOpen} setIsOpen={setIsOpen}/>
            <Row>
                <Col md={5}>
                    <GroupsControl refreshApp={refreshApp} type={object_name} group={pagination.group} setGroup={handleChangeGroup}/>
                </Col>
                <Col md={6}>
                    <BulkActions refreshApp={refreshApp} checked={checked} uncheckAll={uncheckAll} group={bulkGroup} setGroup={setBulkGroup} type={object_name} handleBulkMove={handleBulkMove} handleBulkDelete={handleBulkDelete}/>
                </Col>
                <Col md={1}>
                    <CountSelect pagination={pagination} handleCountChange={handleCountChange}/>
                </Col>
            </Row>
            <hr/>
            <Table striped bordered hover responsive>
                <thead>
                <tr>
                    <th width={"1%"}>
                        <FormCheckInput inline label="1" name="group1" className="custom_mr-2" type="checkbox" value={0} checked={isAllCheckboxesChecked()} onChange={checkAllCheckboxes} id={`reverse-checkbox-0`}/>
                    </th>
                    <th>
                        <SortHeader name="Id" pagination={pagination} order={0} handleChangeSort={handleChangeSort}/>
                    </th>
                    <th>
                        <SortHeader name="Name" pagination={pagination} order={1} handleChangeSort={handleChangeSort}/>
                    </th>
                    <th>
                        <SortHeader name="Description" pagination={pagination} order={2} handleChangeSort={handleChangeSort}/>
                    </th>
                    <th>
                        <SortHeader name="Registered" pagination={pagination} order={3} handleChangeSort={handleChangeSort}/>
                    </th>
                    <th>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    records.map((record) => (
                        <tr role="button">
                            <td onClick={() => handleCheckboxChange(record.id)}>
                                <FormCheckInput inline label={record.id} name="group1" type="checkbox" value={record.id} id={`inline-checkbox-${record.id}`} checked={isOneCheckboxesChecked(record.id)} onChange={() => handleCheckboxChange(record.id)}/>
                            </td>
                            <td onClick={() => handleCheckboxChange(record.id)}>
                                {record.id}
                            </td>
                            <td onClick={() => handleCheckboxChange(record.id)}>
                                {cutText(record.name)}
                            </td>
                            <td onClick={() => handleCheckboxChange(record.id)}>
                                {cutText(record.description)}
                            </td>
                            <td onClick={() => handleCheckboxChange(record.id)}>
                                {(() => {
                                    // Convert registered timestamp to string
                                    return new Date(record.registered*1000).toUTCString()
                                })()}
                            </td>
                            <td className={"float-end"}>
                                <InputGroup size={"sm"}>
                                    <Button variant={"outline-primary"} value={record.id} onClick={HandleEditClicked}>Edit</Button>
                                    <Button variant={"outline-danger"} value={record.id} onClick={handleDeleteClicked}>Delete</Button>
                                </InputGroup>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
            <CustomPagination pagination={pagination} handleChangePage={handleChangePage}/>
            <Button variant="outline-primary" size="sm" onClick={HandleCreateClicked}>Create</Button>
        </>
    )


}