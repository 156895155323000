

import Modal from "react-bootstrap/Modal";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Col, Form, FormControl, FormText, Row} from "react-bootstrap";

import Transport from "../../../transport/Transport";
import Button from "react-bootstrap/Button";


export default function PresetModal({refreshApp, type, param, isOpen, setIsOpen, setPreset, record, setRecord, value}) {

    function closeModal ()  {
        // Set state to closed
        setIsOpen(false);
    }

    function onValueChange (key, value) {
        var newRecord = Object.assign({}, record); //JSON.parse(JSON.stringify(record));
        newRecord[key] = value;
        setRecord(newRecord);
        console.log("NEW RECORD")
    }

    function handleEditFunction (key) {
        // Add determinated param to event
        return (e) => onValueChange(key, e.target.value);
    }

    function handleUpdateResponse(response) {
        setPreset(response.id);
        setIsOpen(false);
    }

    function handleSaveFunction() {
        let newRecord = Object.assign({}, record);

        newRecord['type'] = type;
        newRecord['param'] = param;
        newRecord['data'] = value;

        Transport("presets", "update", newRecord, handleUpdateResponse, refreshApp);
    }

    // useEffect(() => {
    //     handleSaveFunction();
    // }, [value]);

    return (
        <Modal show={isOpen} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    { record.id === 0 ? <>Create</> : <>Edit</> } Type {type} preset
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        {
                            Object.keys(record).map((key, index) =>
                            {
                                switch (key) {
                                    // Do not diplay
                                    case "id":
                                        return <></>

                                    // Display as default(text)
                                    default:
                                        return <>
                                            <Col md={6} key={"col_" + key}>
                                                <FormText key={"label_" + key}>[<strong>{key}</strong>]</FormText>
                                                <FormControl key={"input_" + key} type="text" size="sm" onChange={handleEditFunction(key)} defaultValue={record[key]} className={"mt-1"}/>
                                            </Col>
                                        </>
                                }
                            })
                        }
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size={"sm"} variant="outline-primary" onClick={handleSaveFunction}>Save</Button>
                <Button size={"sm"} variant="outline-secondary"  onClick={closeModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}