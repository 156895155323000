import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import {InputGroup, FormText, Col} from "react-bootstrap";
import GroupSelect from "./GroupSelect";

import GroupModal from "../modals/GroupModal";
import Transport from "../../../transport/Transport";
import ConfirmationModal from "../modals/ConfirmationModal";

export default function GroupsControl({refreshApp, type, group, setGroup}) { //{groups, pagination, handleGroupChange, gname, setGname, handleGroupCreate, handleGroupDelete}) {

    const [record, setRecord] = useState({})
    const [isOpen, setIsOpen] = useState(false)

    function handleRecordResponse(response) {
        setRecord(response.record);
        setIsOpen(true);
    }

    function handleDeleteResponse(response) {
        setGroup(0);
    }

    function handleDeleteClicked (event) {

        Transport("groups", "remove", {'id': group, 'type': type}, handleDeleteResponse, refreshApp);
    }

    function HandleEditClicked(event) {
        Transport("groups", "get", {'id': group, 'type': type}, handleRecordResponse, refreshApp);
    }

    function HandleCreateClicked(event) {
        Transport("groups", "default", {'type': type}, handleRecordResponse, refreshApp);
        setIsOpen(true);
    }

    const { Dialog, onOpen } = ConfirmationModal({
        headerText: "Group remove confirmation",
        bodyText: "Remove group " + group + "?",
        buttonText: "Confirm",
        cancelText: "Cancel",
        handleConfirmFunction: handleDeleteClicked
    });

    return (
        <>
            <Dialog/>
            <GroupModal refreshApp={refreshApp} type={type} record={record} setRecord={setRecord} isOpen={isOpen} setIsOpen={setIsOpen} setGroup={setGroup}/>
                {/*<FormText>Select / create / delete group {type}</FormText>*/}
                <InputGroup size={"sm"}>
                    <GroupSelect refreshApp={refreshApp} type={type} group={group} setGroup={setGroup}/>
                    { group !== undefined && group !== 0 ?
                        <>
                        <Button variant="outline-primary" onClick={HandleEditClicked}>Edit</Button>
                        <Button variant="outline-danger" onClick={onOpen}>Remove</Button>
                        </>
                        :
                        <></>
                    }
                    <Button variant="outline-primary" onClick={HandleCreateClicked}>Create</Button>
                </InputGroup>
        </>
    );
}