import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {InputGroup, Pagination, Form, PageItem, Row, Col, FormSelect, FormText, FormControl} from "react-bootstrap";

import GroupSelect from "../../../shared/components/GroupSelect";
import IndicatorSelect from "../../../shared/components/IndicatorSelect";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import Transport from "../../../../transport/Transport";
import PresetsControl from "../../../shared/components/PresetsControl";


export default function TimeFramesIndicatorsControl({refreshApp, frameindicator, setFrameindicator}) {

    const [indicators, setIndicators] = useState([]);
    const [tickers, setTickers] = useState([]);


    function handleChange (index, value) {
        let newfid = JSON.parse(JSON.stringify(frameindicator));
        newfid[index][2] = value;
        setFrameindicator(newfid);
    }

    function handleTimeframeEdit(event){
        let val = parseInt(event.target.value);
        let index = event.target.attributes.index.value;
        console.log("TF_EDIT", val, index);
        let newfid = JSON.parse(JSON.stringify(frameindicator));
        if (!isNaN(val) && val > 0) newfid[index][1] = val;
        setFrameindicator(newfid);
    }

    function addNew(e) {
        let newfid = JSON.parse(JSON.stringify(frameindicator));
        newfid.push(["",1,0]);
        setFrameindicator(newfid);
    }
    function Remove  (e) {
        let index = e.target.attributes.index.value;
        let newfid = JSON.parse(JSON.stringify(frameindicator));
        if (newfid.length > 1) {
            //if (newfid[index][2]) {
            //    newfid[0][2] = true;
           // }
            newfid.splice(index, 1);
        }
        setFrameindicator(newfid);
    }

    function handleListResponse(response) {
        setIndicators(response.list);
    }

    function handleTickersListResponse(response) {
        setTickers(response.list);
    }

    useEffect(() => {
        Transport("indicators", "full_list", {}, handleListResponse, refreshApp);
        Transport("tickers", "list", {}, handleTickersListResponse, refreshApp);
        addNew();
    }, []);

    function handleTickerChange(event) {
        let val = event.target.value;
        let index = event.target.attributes.index.value;
        let newfid = JSON.parse(JSON.stringify(frameindicator));
        newfid[index][0] = val;
        setFrameindicator(newfid);
        console.log(newfid);

    }

    // const handleRadio = (e) => {
    //     let index = e.target.attributes.index.value;
    //     let newfid = JSON.parse(JSON.stringify(frameindicator));
    //     for (let i =0; i < newfid.length; i++) newfid[i][2] = false;
    //     newfid[index][2] = true;
    //     setFrameindicator(newfid);
    // }

    return (
        <>
            <Row>
                <Col md={6} className={"mt-1"}>
                    <FormText>[timeframe] [indicator]</FormText>
                {frameindicator.map((item, index) => (
                    <>
                        <InputGroup size={"sm"} className={"mt-1"}>
                            <FormSelect index={index} onChange={handleTickerChange} value={item[0]}  size="sm">
                                    <option value="">[Default]</option>
                                    {tickers.map(option => (
                                        <option value={option}>{option.toUpperCase()}</option>
                                    ))}
                            </FormSelect>
                            <InputGroup.Text index={index}>Timeframe</InputGroup.Text>
                            <FormControl size="sm" type="number" index={index} value={item[1]}  defaultValue={item[1]} onChange={handleTimeframeEdit}/>
                            <InputGroup.Text index={index}>Indicator</InputGroup.Text>
                            <IndicatorSelect indicators={indicators} index={index} handleChange={handleChange} value={item[2]}/>
                            <Button variant="outline-danger" index={index} onClick={Remove}>Remove</Button>
                        </InputGroup>
                    </>
                ))}
                </Col>
                <Col md={1} className={"mt-1"}>
                    <FormText>&nbsp;</FormText>
                    <InputGroup className="input-group-sm mt-1">
                        <Button variant="outline-primary" onClick={addNew}>Add</Button>
                    </InputGroup>
                </Col>
                <Col md={5} className={"mt-1"}>
                    <FormText>[presets]</FormText>
                    <PresetsControl refreshApp={refreshApp} type={1} param={0} value={frameindicator} setValue={setFrameindicator}/>
                </Col>
            </Row>
        </>
    );
}