import {Accordion, Badge, Col, FormControl, FormSelect, InputGroup, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Transport from "../../../transport/Transport";
import Button from "react-bootstrap/Button";
import RobotModal from "./RobotModal";
import GroupsControl from "../../shared/components/GroupsControl";
import CustomPagination from "../../shared/components/CustomPagination";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import SortHeader from "../../shared/components/SortHeader";
import ConfirmationModal from "../../shared/modals/ConfirmationModal";
import BulkActions from "../../shared/components/BulkActions";
import CountSelect from "../../shared/components/CountSelect";

import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {renderCharts} from "./components/PositionCharts";
import PositionModal from "../backtests/modals/PositionModal";


export default function RobotsTable({refreshApp}) {

    // Main settings
    const object_name = "robots"
    const checkboxes_checked_name = "checked_robots"
    const pagination_name = "pagination_robots"

  //  localStorage.removeItem(checkboxes_checked_name);
  //  localStorage.removeItem(pagination_name);

    // Loading from storage
    let loaded_pagination = {};
    let temp = localStorage.getItem(pagination_name)
    if (temp !== "undefined") {
        let get_pagination = JSON.parse(temp);
        if (get_pagination != null) loaded_pagination = get_pagination;

    }
    console.log("LOADED PAA", loaded_pagination);
    let loaded_checked = [];
    temp = localStorage.getItem(checkboxes_checked_name)
    if (temp !== "undefined") {
        let get_checked = JSON.parse(temp);
        if (get_checked != null) loaded_checked = get_checked;
    }

    console.log("PAGINATION", loaded_pagination)

    // For list display
    const [records, setRecords] = useState([]);
    const [pagination, setPagination] = useState(loaded_pagination);

    // For edit in modal

    const [record, setRecord] = useState({});
    const [position, setPosition] = useState({});
    const [settings, setSettings] = useState({});


    const [accounts, setAccounts] = useState({});

    const [isOpen, setIsOpen] = useState(false);

    // For check items
    const [checked, setChecked] = useState(loaded_checked);

    // For bulk actions
    const [bulkGroup, setBulkGroup] = useState(loaded_checked);


    const [rendered, setRendered] = useState([]);

    const [positionModalIsOpen, setPositionModalOpen] = useState(false)

    // Main list render
    function handleListResponse(response) {
        setRecords(response.list);
        setPagination(response.pagination);
        localStorage.setItem(pagination_name, JSON.stringify(response.pagination));
    }

    function handleAccountsListResponse(response) {
        setAccounts(response.list);

    }


    function update(pagination) {
        Transport(object_name, "list", pagination, handleListResponse, refreshApp);
        Transport("accounts", "list", pagination, handleAccountsListResponse, refreshApp);
    }

    useEffect(() => {
        update(pagination);
    }, [isOpen]);

    // Group update
    function handleChangeGroup(newGroup) {
        let newPagination = Object.assign({}, pagination);
        newPagination['group'] = newGroup;
        setBulkGroup(newGroup);
        setPagination(newPagination);
        update(newPagination);
    }

    // Crud
    function handleRecordResponse(response) {
        response.record.gid = pagination.group;
        setRecord(response.record);
        setIsOpen(true);
    }

    function HandleCreateClicked(event) {
        Transport(object_name, "default", {}, handleRecordResponse, refreshApp);
    }

    // Pages
    function handleChangePage(newPage) {
        let newPagination = Object.assign({}, pagination);
        newPagination['page'] = newPage;
        setPagination(newPagination);
        update(newPagination);
    }

    // Checkboxes
    const handleCheckboxChange = (data) => {
        const isChecked = checked.some(checkedCheckbox => checkedCheckbox === data)
        if (isChecked) {
            setChecked(checked.filter((checkedCheckbox) => checkedCheckbox !== data));
            localStorage.setItem(checkboxes_checked_name, JSON.stringify(checked.filter((checkedCheckbox) => checkedCheckbox !== data)));
        } else {
            setChecked(checked.concat(data));
            localStorage.setItem(checkboxes_checked_name, JSON.stringify(checked.concat(data)));
        }

    };

    const isAllCheckboxesChecked = () => {
        if (records.length === 0) return false;
        for (let i = 0; i < records.length; i++) {
            if (!checked.includes(records[i].id)) {
                return false;
            }
        }
        return true;
    }

    const isOneCheckboxesChecked = (id) => {
        return checked.includes(id);
    }

    const checkAllCheckboxes = (e) => {
        let i = 0;
        let state = e.target.checked;
        let new_checked = JSON.parse(JSON.stringify(checked));
        if (state) {
            for (i = 0; i < records.length; i++) {
                if (!new_checked.includes(records[i].id)) {
                    new_checked = new_checked.concat(records[i].id);
                    setChecked(new_checked);
                }
            }
        } else {
            for (i = 0; i < records.length; i++) {
                new_checked = new_checked.filter(item => item !== records[i].id)
                setChecked(new_checked);

            }
        }
        localStorage.setItem(checkboxes_checked_name, JSON.stringify(new_checked));
    }

    function uncheckAll() {
        setChecked([]);
        localStorage.setItem(checkboxes_checked_name, JSON.stringify([]));
    }

    // Sort
    const handleChangeSort = (sort, desc) => {
        let newPagination = Object.assign({}, pagination);
        newPagination.order = parseInt(sort);
        newPagination.desc = desc;
        setPagination(newPagination);
        update(newPagination);
    }

    const cutText = (text) => {
        if (text.length > 60) text = text.substring(0, 60) + "...";
        return text;
    }

    //Delete
    function handleUpdatedResponse(reponse) {
        update(pagination);
    }

    function handleConfirmClicked (event) {
        Transport(object_name, "remove", {'id': record.id}, handleUpdatedResponse, refreshApp);
    }

    const { Dialog, onOpen } = ConfirmationModal({
        headerText: "Engine remove confirmation",
        bodyText: "Remove engine " + record.id + "?",
        buttonText: "Confirm",
        cancelText: "Cancel",
        handleConfirmFunction: handleConfirmClicked
    });


    // Edit
    function HandleEditClicked(event) {
        Transport(object_name, "get", {'id': parseInt(event.target.value)}, handleRecordResponse, refreshApp);
    }


    function handleDeleteClicked (event) {
        setRecord({'id': parseInt(event.target.value)});
        onOpen();
    }

    // Bulk actions
    function handleBulkMove(group) {
        Transport(object_name, "bulk_update", {'bulk': checked, 'group': group}, handleUpdatedResponse, refreshApp);
        uncheckAll();
    }

    function handleBulkDelete() {
        Transport(object_name, "bulk_remove", {'bulk': checked}, handleUpdatedResponse, refreshApp);
        uncheckAll();
    }

    // Display count
    function handleCountChange(newCount) {
        let newPagination = Object.assign({}, pagination);
        newPagination.count =newCount;
        setPagination(newPagination);
        update(newPagination);
    }

    function prepareUrl(record) {
        let sets = JSON.parse(record.settings);
        let url = "?bid=" + record['sid'];
        for(const [key, value] of Object.entries(sets)) {
            if (key !== 'Unnamed: 0') {
                url += "&";
                url += key + "=" + value;
            }
        }
        return url;
    }

    function goUrl  (e) {
        e.preventDefault();
        let url = "/strategy" + e.target.attributes.url.value;
        window.open(url, "_blank");
    }




    function handleActionResponse(response) {
       update(pagination);
    }

    function startClick (robot_id, value) {
        Transport("robots", "start", {'id': robot_id}, handleActionResponse)
    }

    function handleStartClick(robot_id) {
        // Add determinated param to event
        return (e) => startClick(robot_id, e.target.value);
    }

    function stopClick (robot_id, value) {
        Transport("robots", "stop", {'id': robot_id}, handleActionResponse)
    }

    function handleStopClick(robot_id) {
        // Add determinated param to event
        return (e) => stopClick(robot_id, e.target.value);

    }

    function newHandler(record, settings, position) {
        return (json) => {
            console.log("GOT CHART", json.chart);
            console.log("SETTINGS", settings);
            let take = parseFloat(settings['min_take']);
            if (record.type === 1) take = -take;
            console.log("CCCHHAARRRTT", json);
            var new_rendered = renderCharts(json.chart, take, record, position, rendered);
            setRendered(new_rendered);
        }
    }


    function getChart(positionChartSettings, record, settings, position) {
        Transport('robots', 'chart', positionChartSettings, newHandler(record, settings, position), refreshApp);
    }

    const openPositionModal = (cur_record, index, e) => {


        /* Convert */
        let i = 0;
        //console.log("RECORD", record)
        let trades = []
        for (i = 0; i < cur_record['positions'].length; i++) {
            console.log("PPPPOSSS", cur_record['positions'][i]);
            let pos_state = JSON.parse(cur_record['positions'][i]['state'])
            let open_position = pos_state['open_position']


            if (open_position['is_open'] === 1) {
                //alert(open_position['last_time']);
                cur_record['positions'][i]['closed'] = pos_state['current_time'] * 1000;
            }// else {
           //     cur_record['positions'][i]['closed'] =  cur_record['positions'][i]['closed'] * 1000;
          //  }


            let ready = {
                "is_open": open_position['is_open'],
                "deposit": pos_state['deposit'],
                "actual_profit": open_position['actual_profit'],
                "liquidation": pos_state['liquidations'],
                "open_time": open_position['open_time'] * 1000,
                "close_time": cur_record['positions'][i]['closed'],
                "open_price": open_position['avg_price'],
                "close_price": pos_state['current_price'],
                "min_profit": open_position['max_pain'],
                "profit": open_position['actual_profit'],
                "duration": open_position['last_time'] * 1000 - open_position['open_time'] * 1000,
                "volume": open_position['volume'],
                "min_take": 0,
                "reason": "NOT SAVED",
                "sub": []
            }
            if (open_position['stop_loss'] !== undefined) {
                ready['stop_loss'] = open_position['stop_loss']
            }

            if (open_position['take_profit'] !== undefined) {
                ready['take_profit'] = open_position['take_profit']
            }

            let state = JSON.parse(cur_record['positions'][i]['state'])
            let y = 0;
            console.log("STATE", state)
            for (y = 0; y < state['open_position']['open_orders'].length; y++) {
                ready['sub'].push({
                  //"{"time": 1720957560, "price": 25.933001, "volume": 2091.096924, "profit": -24.308502}]}, "current_price": "25.635", "current_time": "1720960560000"}"
                    "open_time": state['open_position']['open_orders'][y]['time'],
                    "open_price": state['open_position']['open_orders'][y]['price'],
                    "close_time": ready['close_time'],
                    "close_price": open_position['last_price'],
                    "min_profit": 0,
                    "profit": state['open_position']['open_orders'][y]['profit'],
                    "duration": (ready['close_time'] - state['open_position']['open_orders'][y]['time'] * 1000) / 1000 / 60,
                    "volume": state['open_position']['open_orders'][y]['volume']
                })
            }
            trades.push(ready);
        }

        setSettings(JSON.parse(cur_record['settings']))
        setRecord(cur_record)
        setPosition(trades[index])
        //alert(trades[index].duration);
        let margin = trades[index].duration / 3 * 1000;
        if (margin < 60 * 60 * 1000) margin = 60 * 60 * 1000

        let chart_settings = {'rid': cur_record['id'], 'start': trades[index].open_time  - margin, 'stop': trades[index].close_time  + margin}
        setPositionModalOpen(true);
        getChart(chart_settings, cur_record, JSON.parse(cur_record['settings']), trades[index]);
    }



    function handlePositionClick(record, index) {
        // Add determinated param to event
        return (e) => openPositionModal(record, index, e.target.value);
    }




    const onRobotAccountChange = (key, value) => {
        //Copy current values
        let newRecords = JSON.parse(JSON.stringify(records));
        for (let i = 0; i < records.length; i++) {
            if (records[i]['id'] === parseInt(key)) newRecords[i]['aid'] = parseInt(value);
        }
        setRecords(newRecords);
        Transport('robots', 'update_account',  {'id': key, 'aid': value}, (resp) => {}, refreshApp);
    }



    function handleRobotAccountChange(key) {
        return (e) => onRobotAccountChange(key, e.target.value);
    }




    return (
        <>
            <hr/>
            <PositionModal isOpen={positionModalIsOpen} setModalOpen={setPositionModalOpen} record={record} position={position} settings={settings}/>
            <Dialog/>
            <RobotModal refreshApp={refreshApp} record={record} setRecord={setRecord} isOpen={isOpen} setIsOpen={setIsOpen}/>
            <Row>
                <Col md={5}>
                    <GroupsControl refreshApp={refreshApp} type={object_name} group={pagination.group} setGroup={handleChangeGroup}/>
                </Col>
                <Col md={6}>
                    <BulkActions refreshApp={refreshApp} checked={checked} uncheckAll={uncheckAll} group={bulkGroup} setGroup={setBulkGroup} type={object_name} handleBulkMove={handleBulkMove} handleBulkDelete={handleBulkDelete}/>
                </Col>
                <Col md={1}>
                    <CountSelect pagination={pagination} handleCountChange={handleCountChange}/>
                </Col>
            </Row>
            <hr/>
            <Table striped bordered hover responsive>
                <thead>
                <tr>
                    <th width={"1%"}>
                        <FormCheckInput inline label="1" name="group1" className="custom_mr-2" type="checkbox" value={0}
                                        checked={isAllCheckboxesChecked()} onChange={checkAllCheckboxes}
                                        id={`reverse-checkbox-0`}/>
                    </th>
                    <th>
                        <SortHeader name="Id" pagination={pagination} order={0} handleChangeSort={handleChangeSort}/>
                    </th>
                    <th>
                        <SortHeader name="Type" pagination={pagination} order={0} handleChangeSort={handleChangeSort}/>
                    </th>
                    <th>
                        <SortHeader name="Status" pagination={pagination} order={0}
                                    handleChangeSort={handleChangeSort}/>
                    </th>
                    <th>
                        <SortHeader name="Deposit" pagination={pagination} order={0}
                                    handleChangeSort={handleChangeSort}/>
                    </th>
                    <th>
                        <SortHeader name="Control" pagination={pagination} order={0}
                                    handleChangeSort={handleChangeSort}/>
                    </th>
                    <th>
                        <SortHeader name="Sources" pagination={pagination} order={0}
                                    handleChangeSort={handleChangeSort}/>
                    </th>
                    <th>
                        <SortHeader name="Name" pagination={pagination} order={1} handleChangeSort={handleChangeSort}/>
                    </th>
                    <th>
                        <SortHeader name="Registered" pagination={pagination} order={3}
                                    handleChangeSort={handleChangeSort}/>
                    </th>
                    <th>
                    </th>
                </tr>
                </thead>
                <tbody>


                {
                    records.map((record) => (
                        <>
                            {(() => {
                                let robot_state = JSON.parse(record['initial_state'])
                                console.log(robot_state);
                                return (<>
                                    <tr role="button">
                                        <td onClick={() => handleCheckboxChange(record.id)}>
                                            <FormCheckInput inline label={record.id} name="group1" type="checkbox"
                                                            value={record.id} id={`inline-checkbox-${record.id}`}
                                                            checked={isOneCheckboxesChecked(record.id)}
                                                            onChange={() => handleCheckboxChange(record.id)}/>
                                        </td>
                                        <td onClick={() => handleCheckboxChange(record.id)}>
                                            {record.id}
                                        </td>
                                        <td onClick={() => handleCheckboxChange(record.id)}>
                                            {record.type === 0 ? (<div className="text-success">Long</div>) : (
                                                <div className="text-danger">Short</div>)}
                                        </td>
                                        <td onClick={() => handleCheckboxChange(record.id)}>
                                            {record.status === 0 ?
                                                (<Badge bg={"danger"}>Stopped</Badge>) :
                                                record.force_close === 1 ?
                                                    <Badge bg={"danger"}>Closing</Badge> :
                                                    record.status === 1 ? robot_state.open_position?.open_orders_count > 0 ?

                                                            (<Badge bg={"success"}>In position</Badge>) :
                                                            (<Badge bg={"warning"}>Running</Badge>) :
                                                        <></>
                                            }
                                        </td>
                                        <td onClick={() => handleCheckboxChange(record.id)}>
                                            {robot_state['deposit']}
                                        </td>
                                        <td>
                                            {
                                                record.force_close === 1 ? <></> :
                                                    record.status === 0 ? (
                                                            <Button variant={'outline-success'} size={"sm"}
                                                                    onClick={handleStartClick(record['id'])}>Start</Button>) :
                                                        record.status === 1 ? robot_state.open_position?.open_orders_count > 0 ?
                                                                <Button variant={'danger'} size={"sm"}
                                                                        onClick={handleStopClick(record['id'])}>Close</Button>
                                                                : <Button variant={'outline-danger'} size={"sm"}
                                                                          onClick={handleStopClick(record['id'])}>Stop</Button>
                                                            : <></>
                                            }
                                        </td>
                                        <td onClick={() => handleCheckboxChange(record.id)}>
                                            {record.sources.map(item => (
                                                <>
                                                    <Badge className={"me-1"}>{item.toUpperCase()}</Badge>
                                                </>
                                            ))}
                                        </td>

                                        <td onClick={() => handleCheckboxChange(record.id)}>
                                            {cutText(record.name)}
                                        </td>
                                        <td onClick={() => handleCheckboxChange(record.id)}>
                                            {(() => {
                                                // Convert registered timestamp to string
                                                return new Date(record.registered * 1000).toUTCString()
                                            })()}
                                        </td>

                                        <td className={"float-end"}>
                                            <InputGroup size={"sm"}>

                                                <Button variant={"outline-primary"} url={prepareUrl(record)}
                                                        onClick={goUrl}>Source</Button>
                                                <Button variant={"outline-danger"} value={record.id}
                                                        onClick={handleDeleteClicked}>Delete</Button>
                                            </InputGroup>
                                        </td>
                                        <td>
                                            <FormSelect onChange={handleRobotAccountChange(record['id'])}
                                                        value={record['aid']} size="sm">
                                                <option value="0">[No account]</option>
                                                {accounts.map(item => (
                                                    <option value={item.id}>({item.id}){item.api_key}</option>
                                                ))}
                                            </FormSelect>
                                        </td>
                                    </tr>
                                    <tr>

                                        <td colspan={10}>
                                            <table striped bordered hover responsive style={{'width': '100%'}}>

                                                {record['positions'].length > 0 ?
                                                    <>
                                                        <thead>
                                                        <tr>
                                                            <th>Status</th>
                                                            <th>Price</th>
                                                            <th>Current</th>
                                                            <th>Max pain</th>
                                                            <th>Open time</th>
                                                            <th>Current time</th>
                                                            <th>Volume</th>
                                                            <th>Profit</th>
                                                            <th>BVolume</th>
                                                            <th>BProfit</th>
                                                            <th>Stop</th>
                                                            <th>Take</th>
                                                        </tr>
                                                        </thead>
                                                    </>
                                                    :
                                                    <></>
                                                }

                                                <tbody>
                                                {record['positions'].map((position, index) => (
                                                    <>
                                                        {(() => {
                                                            let state = JSON.parse(position['state'])
                                                            let binance_state = {}
                                                            if (position['binance_state'] !== undefined && position['binance_state'] !== "") {
                                                                binance_state = JSON.parse(position['binance_state'])
                                                            }
                                                            return <>
                                                                <tr role={"button"}
                                                                    onClick={handlePositionClick(record, index)}>
                                                                    <td>{state['open_position']['is_open'] === 1 ?
                                                                        <Badge bg="warning">Open</Badge> :
                                                                        <Badge bg="success">Closed</Badge>}</td>
                                                                    <td>{state['open_position']['avg_price']}</td>
                                                                    <td>{state['current_price']}</td>
                                                                    <td>{state['open_position']['max_pain'] < 0 ? <Badge
                                                                            bg="danger">{state['open_position']['max_pain']}</Badge> :
                                                                        <Badge
                                                                            bg="success">{state['open_position']['max_pain']}</Badge>}
                                                                    </td>
                                                                    <td>                                {(() => {
                                                                        // Convert registered timestamp to string
                                                                        return new Date(state['open_position']['open_time'] * 1000).toLocaleString();
                                                                    })()}</td>
                                                                    <td>{(() => {
                                                                        // Convert registered timestamp to string
                                                                        return new Date(parseInt(state['current_time']) * 1000).toLocaleString();
                                                                    })()}</td>

                                                                    <td>{state['open_position']['volume']}

                                                                    </td>

                                                                    <td>
                                                                        {state['open_position']['actual_profit'] < 0 ?
                                                                            <Badge
                                                                                bg="danger">{state['open_position']['actual_profit']}</Badge> :
                                                                            <Badge
                                                                                bg="success">{state['open_position']['actual_profit']}</Badge>}
                                                                    </td>
                                                                    <td>  {binance_state !== {} ?
                                                                        <div>{binance_state['notional']}</div> : <>N/A</>}
                                                                    </td>
                                                                    <td>
                                                                        {binance_state !== {} ?
                                                                            parseFloat(binance_state['unRealizedProfit']) > 0 ?
                                                                                <Badge
                                                                                    bg="success">{binance_state['unRealizedProfit']}</Badge>
                                                                                :
                                                                                <Badge
                                                                                    bg="danger">{binance_state['unRealizedProfit']}</Badge>
                                                                            : <>N/A</>}
                                                                    </td>
                                                                    <td> {state['open_position']['stop_loss']}</td>
                                                                    <td> {state['open_position']['take_profit']}</td>
                                                                </tr>

                                                                <tr>


                                                                    <td colspan={10}>
                                                                        <FormControl value={position['open_candle']}/>
                                                                        <FormControl value={position['close_candle']}/>
                                                                        <FormControl
                                                                            value={position['close_response']}/>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    {state['open_position']['open_orders'].map((order) => (
                                                                        <>
                                                                            <td>-</td>

                                                                            <td>{order['price']}</td>
                                                                            <td></td>
                                                                            <td></td>

                                                                            <td>{(() => {
                                                                                // Convert registered timestamp to string
                                                                                return new Date(order['time'] * 1000).toLocaleString();
                                                                            })}
                                                                            </td>
                                                                            <td></td>
                                                                            <td>{order['volume']}</td>
                                                                            <td>{order['profit']}</td>
                                                                        </>
                                                                    ))}

                                                                </tr>
                                                                <tr>
                                                                    <td colspan={10}>
                                                                        <hr/>
                                                                    </td>
                                                                </tr>
                                                            </>

                                                        })()}
                                                    </>
                                                ))}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </>)

                            })()}
                        </>
                    ))
                }
                </tbody>
            </Table>
            <CustomPagination pagination={pagination} handleChangePage={handleChangePage}/>
            <Button variant="outline-primary" size="sm" onClick={HandleCreateClicked}>Create</Button>
        </>
    )


}