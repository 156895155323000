import React from "react";

export default function NewPositionHeaderLine({trade}) {

        let values = [];
        values.push(<td placement="top"key={"header_id_wrapper"}><div key={"header_id"}>#</div></td>);
        values.push(<td placement="top"key={"header_open_price_wrapper"}><div key={"header_open_price"}>Open price</div></td>);
        values.push(<td placement="top"key={"header_close_price_wrapper"}><div key={"header_close_price"}>Open price</div></td>);
        values.push(<td placement="top"key={"header_open_time_wrapper"}><div key={"header_open_time"}>Open time</div></td>);
        values.push(<td placement="top"key={"header_close_time_wrapper"}><div key={"header_close_time"}>Close time</div></td>);
        values.push(<td placement="top"key={"header_duration_wrapper"}><div key={"header_duration"}>Duration</div></td>);
        values.push(<td placement="top"key={"header_volume_wrapper"}><div key={"header_volume"}>Volume</div></td>);
        values.push(<td placement="top"key={"header_profit_wrapper"}><div key={"header_profit"}>Profit</div></td>);
        values.push(<td placement="top"key={"header_min_wrapper"}><div key={"header_min"}>Min</div></td>);
        values.push(<td placement="top"key={"header_deposit_wrapper"}><div key={"header_deposit"}>Deposit</div></td>);
        values.push(<td placement="top"key={"header_removed_wrapper"}><div key={"header_removed"}>Removed</div></td>);
        values.push(<td placement="top"key={"header_liq_wrapper"}><div key={"header_liq"}>Liq</div></td>);
        values.push(<td placement="top"key={"header_avg_wrapper"}><div key={"header_avg"}>Avg</div></td>);
        values.push(<td placement="top"key={"header_r_wrapper"}><div key={"header_r"}>R</div></td>);
        return values;
}