import React from "react";
import PropTypes from "prop-types";
import AceEditor from "react-ace";

// import "brace/mode/python";
// import "brace/theme/monokai";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai"

class CodeEditor extends React.PureComponent {
    constructor(props) {
        super(props);

        const { value } = this.props;

        this.state = {
            value
        };
    }
    componentDidUpdate(prevProps) {
        const { value } = this.props;

        if (prevProps.value !== value) {
            this.onChange(value);
        }
    }
    onChange = value => {
        const { onChange } = this.props;
        this.setState({ value });
        onChange(this.props.name, value);
    };

    getValue = () => {
        const { value } = this.state;
        return value;
    };

    render() {
        const { value } = this.state;

        return (
            <AceEditor
                theme="twilight"
                showPrintMargin={false}
                editorProps={{ $blockScrolling: true }}
                key={"input_" + this.props.name}
                {...this.props}
                onChange={this.onChange}
                value={value}
                setOptions={{ useWorker: false }}
            />
        );
    }
}

CodeEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};

CodeEditor.defaultProps = {
    value: "",
    onChange: () => null
};

export default CodeEditor;
