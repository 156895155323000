import {Table} from "react-bootstrap";
import {useState} from "react";
import GrouptestsTable from "./components/GrouptestsTable";
//import BacktestsTable from "./BacktestsTable";

export default function Grouptests({refreshApp}) {

    return (
        <>
            <div className={"mt-3"}>
                <h5>Group tests</h5>
            </div>
            <GrouptestsTable refreshApp={refreshApp}/>
        </>
    )

}