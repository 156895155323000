import {Table} from "react-bootstrap";
import {useState} from "react";
import BacktestsTable from "./components/BacktestsTable";


export default function Backtests({refreshApp}) {
    return (
        <>
            <div className={"mt-3"}>
                <h5>Backtests</h5>
            </div>
            <BacktestsTable refreshApp={refreshApp}/>
        </>
    )
}