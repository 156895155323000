import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Form, FormControl, FormText, InputGroup} from "react-bootstrap";
import React, {useCallback, useState} from 'react';

export default function ConfirmationModal({
                                              headerText = "Confirmation",
                                              bodyText = "Do you confirm action?",
                                              buttonText = 'Confirm',
                                              cancelText = 'Cancel',
                                              handleConfirmFunction
                                          }
) {

    // Modal dialog state
    const [isOpen, setIsOpen] = useState(false);

    const onOpen = () => {
        // Set state to open.
        setIsOpen(true);
    };

    const closeModal = () => {
        // Set state to closed
        setIsOpen(false);
    }

    const handleConfirmPressed = (e) => {
        // Call confirm handler (delete, logout, ect...)
        handleConfirmFunction();
        closeModal();
    }

    const Dialog = useCallback(
        () => (
            <Modal className="dark" show={isOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {headerText}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {bodyText}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" className="btn-sm"
                            onClick={handleConfirmPressed}>{buttonText}</Button>
                    <Button variant="outline-secondary" className="btn-sm" onClick={closeModal}>{cancelText}</Button>
                </Modal.Footer>
            </Modal>
        ),
        [isOpen]
    );

    // Return Dialog callback and opener function
    return {
        Dialog,
        onOpen
    };

}


