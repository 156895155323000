import ConfirmationModal from "../shared/modals/ConfirmationModal";
import Button from "react-bootstrap/Button";
import Transport from "../../transport/Transport";
import Nav from 'react-bootstrap/Nav';

export default function LogoutButton({refreshApp, buttonText = 'Logout'}) {

    const { Dialog, onOpen } = ConfirmationModal({
        headerText: "Logout confirmation",
        bodyText: "Destroy session?",
        buttonText: "Yes, Logout",
        cancelText: "No!!!!",
        handleConfirmFunction: handleLogoutConfirm
    });

    function handleLogoutResponse(response) {
        localStorage.removeItem("flapary_token");
        refreshApp();
    }

    function handleLogoutConfirm() {
        Transport("auth", "logout", {}, handleLogoutResponse, refreshApp);
    }

    const handleLogoutClick = () => {
        onOpen();
    };

    return (
        <>
            <Nav.Link onClick={handleLogoutClick}>{buttonText}</Nav.Link>
            <Dialog />
        </>
    );

}