import {Badge, Col, InputGroup, OverlayTrigger, ProgressBar, Row, Table, Tooltip} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Transport from "../../../../transport/Transport";
import Button from "react-bootstrap/Button";
import BacktestModal from "../modals/BacktestModal";
import GroupsControl from "../../../shared/components/GroupsControl";
import CustomPagination from "../../../shared/components/CustomPagination";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import SortHeader from "../../../shared/components/SortHeader";
import ConfirmationModal from "../../../shared/modals/ConfirmationModal";
import BulkActions from "../../../shared/components/BulkActions";
import CountSelect from "../../../shared/components/CountSelect";

import { Sparklines } from 'react-sparklines';
import {SparklinesLine} from "react-sparklines";
import {Link} from "react-router-dom";

export default function BacktestsTable({refreshApp}) {

    // Main settings
    const object_name = "backtests"
    const checkboxes_checked_name = "checked_backtests"
    const pagination_name = "pagination_backtests"

  //  localStorage.removeItem(checkboxes_checked_name);
  //  localStorage.removeItem(pagination_name);

    // Loading from storage
    let loaded_pagination = {};
    let temp = localStorage.getItem(pagination_name)
    if (temp !== "undefined") {
        let get_pagination = JSON.parse(temp);
        if (get_pagination != null) loaded_pagination = get_pagination;

    }
    console.log("LOADED PAA", loaded_pagination);
    let loaded_checked = [];
    temp = localStorage.getItem(checkboxes_checked_name)
    if (temp !== "undefined") {
        let get_checked = JSON.parse(temp);
        if (get_checked != null) loaded_checked = get_checked;
    }

    console.log("PAGINATION", loaded_pagination)

    // For list display
    const [records, setRecords] = useState([]);
    const [pagination, setPagination] = useState(loaded_pagination);

    // For edit in modal
    const [record, setRecord] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    // For check items
    const [checked, setChecked] = useState(loaded_checked);

    // For bulk actions
    const [bulkGroup, setBulkGroup] = useState(loaded_checked);

    // Main list render
    function handleListResponse(response) {
        setRecords(response.list);
        setPagination(response.pagination);
        localStorage.setItem(pagination_name, JSON.stringify(response.pagination));
    }

    function update(pagination) {
        Transport(object_name, "list", pagination, handleListResponse, refreshApp);
    }

    useEffect(() => {
        update(pagination);
    }, [isOpen]);

    // Group update
    function handleChangeGroup(newGroup) {
        let newPagination = Object.assign({}, pagination);
        newPagination['group'] = newGroup;
        setBulkGroup(newGroup);
        setPagination(newPagination);
        update(newPagination);
    }

    // Crud
    function handleRecordResponse(response) {
        response.record.gid = pagination.group;
        setRecord(response.record);
        setIsOpen(true);
    }

    function HandleCreateClicked(event) {
        Transport(object_name, "default", {}, handleRecordResponse, refreshApp);
    }

    // Pages
    function handleChangePage(newPage) {
        let newPagination = Object.assign({}, pagination);
        newPagination['page'] = newPage;
        setPagination(newPagination);
        update(newPagination);
    }

    // Checkboxes
    const handleCheckboxChange = (data) => {
        const isChecked = checked.some(checkedCheckbox => checkedCheckbox === data)
        if (isChecked) {
            setChecked(checked.filter((checkedCheckbox) => checkedCheckbox !== data));
            localStorage.setItem(checkboxes_checked_name, JSON.stringify(checked.filter((checkedCheckbox) => checkedCheckbox !== data)));
        } else {
            setChecked(checked.concat(data));
            localStorage.setItem(checkboxes_checked_name, JSON.stringify(checked.concat(data)));
        }

    };

    const isAllCheckboxesChecked = () => {
        if (records.length === 0) return false;
        for (let i = 0; i < records.length; i++) {
            if (!checked.includes(records[i].id)) {
                return false;
            }
        }
        return true;
    }

    const isOneCheckboxesChecked = (id) => {
        return checked.includes(id);
    }

    const checkAllCheckboxes = (e) => {
        let i = 0;
        let state = e.target.checked;
        let new_checked = JSON.parse(JSON.stringify(checked));
        if (state) {
            for (i = 0; i < records.length; i++) {
                if (!new_checked.includes(records[i].id)) {
                    new_checked = new_checked.concat(records[i].id);
                    setChecked(new_checked);
                }
            }
        } else {
            for (i = 0; i < records.length; i++) {
                new_checked = new_checked.filter(item => item !== records[i].id)
                setChecked(new_checked);

            }
        }
        localStorage.setItem(checkboxes_checked_name, JSON.stringify(new_checked));
    }

    function uncheckAll() {
        setChecked([]);
        localStorage.setItem(checkboxes_checked_name, JSON.stringify([]));
    }

    // Sort
    const handleChangeSort = (sort, desc) => {
        let newPagination = Object.assign({}, pagination);
        newPagination.order = parseInt(sort);
        newPagination.desc = desc;
        setPagination(newPagination);
        update(newPagination);
    }

    function cutText(text) {


        if (text.length > 60) text = text.substring(0, 60) + "...";
        return text;
    }

    //Delete
    function handleUpdatedResponse(reponse) {
        update(pagination);
    }

    function handleConfirmClicked (event) {
        Transport(object_name, "remove", {'id': record.id}, handleUpdatedResponse, refreshApp);
    }

    const { Dialog, onOpen } = ConfirmationModal({
        headerText: "Indicator remove confirmation",
        bodyText: "Remove backtest " + record.id + "?",
        buttonText: "Confirm",
        cancelText: "Cancel",
        handleConfirmFunction: handleConfirmClicked
    });


    // Edit
    function HandleEditClicked(event) {
        Transport(object_name, "get", {'id': parseInt(event.target.value)}, handleRecordResponse, refreshApp);
    }


    function handleDeleteClicked (event) {
        setRecord({'id': parseInt(event.target.value)});
        onOpen();
    }

    // Bulk actions
    function handleBulkMove(group) {
        Transport(object_name, "bulk_update", {'bulk': checked, 'group': group}, handleUpdatedResponse, refreshApp);
        uncheckAll();
    }

    function handleBulkDelete() {
        Transport(object_name, "bulk_remove", {'bulk': checked}, handleUpdatedResponse, refreshApp);
        uncheckAll();
    }

    // Display count
    function handleCountChange(newCount) {
        let newPagination = Object.assign({}, pagination);
        newPagination.count =newCount;

        setPagination(newPagination);
        update(newPagination);
    }

    function calcTotal  (params)  {
        let counter = 0;
        for (var key in params) {
            console.log("Calc key", key);
            let temp = params[key].split(",");
            if (counter === 0) {
                counter = temp.length;
            } else {
                counter = counter * temp.length;
            }
        }
        return counter;
    }


    return (
        <>
            <hr/>
            <Dialog/>
            <BacktestModal refreshApp={refreshApp} record={record} setRecord={setRecord} isOpen={isOpen} setIsOpen={setIsOpen}/>
            <Row>
                <Col md={5}>
                    <GroupsControl refreshApp={refreshApp} type={object_name} group={pagination.group} setGroup={handleChangeGroup}/>
                </Col>
                <Col md={6}>
                    <BulkActions refreshApp={refreshApp} checked={checked} uncheckAll={uncheckAll} group={bulkGroup} setGroup={setBulkGroup} type={object_name} handleBulkMove={handleBulkMove} handleBulkDelete={handleBulkDelete}/>
                </Col>
                <Col md={1}>
                    <CountSelect pagination={pagination} handleCountChange={handleCountChange}/>
                </Col>
            </Row>
            <hr/>
            <Table striped bordered hover responsive>
                <thead>
                <tr>
                    <td width={"1%"}>
                        <FormCheckInput inline label="1" name="group1" className="custom_mr-2" type="checkbox" value={0} checked={isAllCheckboxesChecked()} onChange={checkAllCheckboxes} id={`reverse-checkbox-0`}/>
                    </td>
                    <td>
                        <SortHeader name="Id" pagination={pagination} order={0} handleChangeSort={handleChangeSort}/>
                    </td>
                    <td>
                        <SortHeader name="T" pagination={pagination} order={1} handleChangeSort={handleChangeSort}/>
                    </td>
                    <td>
                        <SortHeader name="Sources" pagination={pagination} order={2} handleChangeSort={handleChangeSort}/>
                    </td>
                    <td>
                        <SortHeader name="Status" pagination={pagination} order={3} handleChangeSort={handleChangeSort}/>
                    </td>
                    <td>
                        Duration
                    </td>
                    <td>
                        Strategies
                    </td>
                    <td>
                        <SortHeader name="Comment" pagination={pagination} order={4} handleChangeSort={handleChangeSort}/>
                    </td>

                    <td>
                        <SortHeader name="Registered" pagination={pagination} order={5} handleChangeSort={handleChangeSort}/>
                    </td>

                    <td>
                        Stats
                    </td>

                    <td width={"7%"}>

                    </td>
                    <td width={"12%"}>
                    </td>
                </tr>
                </thead>
                <tbody>
                {
                    records.map((record) => (
                        <tr role="button">
                            <td onClick={() => handleCheckboxChange(record.id)}>

                                <FormCheckInput inline label={record.id} name="group1" type="checkbox" value={record.id} id={`inline-checkbox-${record.id}`} checked={isOneCheckboxesChecked(record.id)} onChange={() => handleCheckboxChange(record.id)}/>

                            </td>
                            <td onClick={() => handleCheckboxChange(record.id)}>

                                {record.id}

                            </td>
                            <td onClick={() => handleCheckboxChange(record.id)}>

                                { record.type === 0 ? (<div className="text-success">Long</div>): (<div className="text-danger">Short</div>)}

                            </td>
                            <td onClick={() => handleCheckboxChange(record.id)}>

                            {record.sources.map(item => (
                                <>
                                    <Badge bg="light" text="black" className={"me-1"}>{item.toUpperCase()}</Badge>
                                </>
                            ))}

                            </td>
                            <td onClick={() => handleCheckboxChange(record.id)}>

                            { record.status === 0 ? (
                                <Badge bg="secondary">Waiting</Badge>
                            ) : record.status === 1 ? (
                                <Badge bg="warning">Downloading</Badge>
                            ) : record.status === 2 ? (
                                <Badge bg="warning">Downloaded</Badge>
                            ) : record.status === 3 ? (
                                <Badge bg="warning">Indicators</Badge>
                            ) : record.status === 4 ? (
                                <Badge bg="warning">Ready</Badge>
                            ) : record.status === 5 ? (
                                <Badge bg="warning">Trading</Badge>
                            ) : record.status === 6 ? (
                                <Badge bg="success">Done</Badge>
                            ) : (
                                <Badge bg="danger">Error</Badge>
                            )
                            }

                            </td>
                            <td onClick={() => handleCheckboxChange(record.id)}>

                            {parseInt((record.stop - record.start) / (24 * 60 * 60))}

                            </td>

                            <td onClick={() => handleCheckboxChange(record.id)}>

                              {calcTotal(JSON.parse(record.generator_settings))}

                            </td>
                            <td onClick={() => handleCheckboxChange(record.id)}>

                                 {cutText(record.comment)}

                            </td>
                            <td onClick={() => handleCheckboxChange(record.id)}>

                                {(() => {
                                    // Convert registered timestamp to string
                                    return new Date(record.registered*1000).toLocaleString();
                                })()}

                            </td>
                            <td onClick={() => handleCheckboxChange(record.id)} >
                                <small>
                                    <small>
                                        <small>
                            {record.status === 6? (
                                <>
                                    {record.stats.size !== 0 ? (
                                        <>
                                            { record.stats.profit_min !== undefined ?
                                                <>
                                                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Alive strategies</Tooltip>}>
                                            <span>{(record.stats.size / (calcTotal(JSON.parse(record.generator_settings)) / 100)).toFixed(2)}% ({record.stats.size})</span>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Profit</Tooltip>}>
                                                <span className="text-success me-1">{record.stats.profit_min.toFixed(2)} - {record.stats.profit_max.toFixed(2)}</span>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Pain</Tooltip>}>
                                            <span className="text-danger me-1">{record.stats.min_min.toFixed(2)} - {record.stats.min_max.toFixed(2)}</span>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Trades count</Tooltip>}>
                                            <span className="text-primary me-1">{record.stats.trades_min.toFixed(0)} - {record.stats.trades_max.toFixed(0)}</span>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Liquidations</Tooltip>}>
                                            <span className="text-danger me-1">{record.stats.liquidations_min.toFixed(0)} - {record.stats.liquidations_max.toFixed(0)}</span>
                                            </OverlayTrigger>
                                                    </>
                                            :
                                                <>Error</>
                                            }
                                        </>
                                    ): (<div>{record.stats.size} Nobody alives</div>)}
                                </>
                            ): (<><div>No stats</div></>)}
                                        </small>
                                    </small>
                                </small>
                            </td>
                            <td onClick={() => handleCheckboxChange(record.id)} >
                            { record.status > 3 && record.status !== 5 ? (
                                <Sparklines data={record.chart} height={40}>
                                    <SparklinesLine color=""/>
                                </Sparklines>
                            ): (
                                <ProgressBar className="mt-1" variant="primary" now={record.progress} min={0} max={100}/>
                            )
                            }
                            </td>


                            <td className={"float-end"}>
                                <InputGroup size={"sm"}>
                                    {record.status === 6? (<Button variant={"outline-success"}><Link role={"Button"} to={`/top?id=${record.id}`}>Top</Link></Button>) : <></>}
                                    {/*<Button variant={"outline-primary"} value={record.id} onClick={HandleEditClicked}>Edit</Button>*/}
                                    <Button variant={"outline-danger"} value={record.id} onClick={handleDeleteClicked}>Delete</Button>
                                </InputGroup>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
            <CustomPagination pagination={pagination} handleChangePage={handleChangePage}/>
            <Button variant="outline-primary" size="sm" onClick={HandleCreateClicked}>Create</Button>
        </>
    )


}