import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import React from 'react';
import PositionChartsContainers from "../components/PositionCharts";

import NewPositionLine from "../components/NewPositionLine";
import NewPositionHeaderLine from "../components/NewPositionHeaderLine";
import StrategySettingsDisplay from "../components/StrategySettingsDisplay";

export default function PositionModal({isOpen, setModalOpen, record, position, settings}) {
    const closeModal = () => {
        setModalOpen(false);
    }

    const empty = () => {

    }
    return (<Modal size="xl" className="modal-lg dark" show={isOpen} onHide={closeModal}>
            <div data-bs-theme="dark" className='bg-dark p-2'>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Position
                    </Modal.Title>
                </Modal.Header>
            </div>
            <Modal.Body>
                <PositionChartsContainers/>
                <div className="custom_div_table mt-3">
                    <div className="custom_div_table_body">
                        <div className="custom_div_table_row">
                            <NewPositionHeaderLine trade={position}/>
                        </div>
                        {[position].map((trade, index) => (
                                <NewPositionLine trade={trade} index={index} openPositionModal={empty}/>
                        ))}
                    </div>
                </div>
                <hr/>
                <StrategySettingsDisplay settings={settings}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" className="btn-sm" onClick={closeModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}


