import {Col, Row, Table} from "react-bootstrap";
import {useState} from "react";
import BacktestsTable from "./components/BacktestsTable";
import {useLocation} from "react-router-dom";
import BacktestTopTable from "./components/BacktestTopTable";
import {Sparklines, SparklinesLine} from "react-sparklines";
import CodeEditor from "../../shared/components/CodeEditor";

export default function BacktestTop({refreshApp}) {

    let location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    const id = searchParams.get('id');

    return (
        <>
            <div className={"mt-3"}>
                <h5>Top</h5>
            </div>
            <BacktestTopTable refreshApp={refreshApp} id={id}/>




        </>
    )

}